import { Line } from "react-chartjs-2";

const MajorMarketChart = ({ chart, ...props }) => {
  return (
    <Line
      {...props}
      datasetIdKey={chart.symbol}
      data={{
        labels: chart.labels,
        datasets: [
          {
            fill: {
              target: {
                value: chart.target,
                // value: chart.data[0],
              },
            },
            label: chart.symbol,
            data: chart.data,
            borderColor: () => {
              if (chart.change < 0) {
                return "rgba(255, 26, 104, 1)";
              } else {
                return "rgba(75, 192, 192, 1)";
              }
            },

            backgroundColor: (context) => {
              const height = context.chart.height;
              const bgGradient = context.chart.ctx.createLinearGradient(
                0,
                0,
                0,
                height
              );

              if (chart.change < 0) {
                bgGradient.addColorStop(0, "rgba(255, 26, 104, 0.5)");
                bgGradient.addColorStop(1, "rgba(255, 26, 104, 0)");
              } else {
                bgGradient.addColorStop(0, "rgba(75, 192, 192, 0.5)");
                bgGradient.addColorStop(1, "rgba(75, 192, 192, 0)");
              }

              return bgGradient;
            },

            borderWidth: 1,
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        elements: {
          point: {
            radius: 0,
            hoverRadius: 0,
          },
          line: {
            cubicInterpolationMode: "monotone",
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          y: {
            display: false,
            // grace: 1,
          },
          x: {
            display: false,
          },
        },
      }}
    />
  );
};

export default MajorMarketChart;
