import React from "react";
import { AnimatePresence, motion } from "framer-motion";
const Input = React.forwardRef(
  (
    {
      name,
      label,
      type,
      placeholder,
      value,
      onChange,
      error,
      leftDefault,
      styles,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="App-Input">
        {label && <label htmlFor={name}>{label}</label>}
        <div className="App-Input_input-container">
          {leftDefault && <span className="left-default">{leftDefault}</span>}
          <input
            ref={ref}
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={leftDefault ? "left-present" : ""}
            {...rest}
          />
        </div>
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10, scale: 0.8 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scaleY: 0.8 }}
              transition={{ type: "spring", duration: 0.8 }}
              className="App-Input_error"
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default Input;
