import { Button, Card, Col, Row, Tabs, Progress, Input } from "antd";
import React, { useContext, useEffect, useState, Link } from "react";
import ContentBox from "../components/ContentBox";
import Headline from "../components/Headline";
import MajorMarketSection from "../components/MajorMarketSection";
import { useGeneral, useMarket } from "../hooks/useStore";
import TinySlider from "tiny-slider-react";
import { useRef } from "react";
import { ReactComponent as DownButtonIcon } from "../assets/icons/down_btn.svg";
import MarketTable from "../components/MarketTable";
import BrowseSection from "../components/BrowseSection";
import Logo from "../assets/images/logo_full.png"
import CONST from "../shared/CONST";
import { InfoCircleOutlined, SearchOutlined, CheckCircleOutlined, UserAddOutlined } from "@ant-design/icons"
import { CardBody, CardFooter, CardTitle, Container } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import InputGroup from 'react-bootstrap/InputGroup';
import ProgressIcon from "../assets/passiv/Protect.svg"
import UserIcon from "../assets/passiv/user.svg"
import RightIcon from "../assets/passiv/icon-right.svg"
import ChartIcon from "../assets/passiv/chart-line.svg"

import chatFiles from "../assets/passiv/chatFiles.svg"
import chatEmptyState from "../assets/passiv/chatEmptyState.svg"
import msgSendIcon from "../assets/passiv/msgSendIcon.svg"
import Banner from "../assets/passiv/banner.jpg"
import User01 from "../assets/passiv/team-3.jpg"
import http, { http_security } from "../services";
import TabPane from "antd/es/tabs/TabPane";
const sliderSettings = {
    lazyload: true,
    items: 6,
    axis: "vertical",
    swipeAngle: false,
    speed: 400,
    mouseDrag: true,
    loop: true,
    controls: false,
};

const marketTypes = [
    { label: "Stocks", value: CONST.MARKET_TYPES.STOCK },
    { label: "Funds", value: CONST.MARKET_TYPES.ETF },
];
const Account = () => {
    const {
        market,
        getMajorMarket,
        getNews,
        getTopPerformers,
        getTrendingStocks,
        getBrowseFilterValues,
        updateMarketType
    } = useMarket();
    const headlinesRef = useRef();
    const [userData, setUserData] = useState()
    const { marketType } = market.browseFilterValues;

    const handleHeadlineNext = () => {
        headlinesRef.current.slider.goTo("next");
    };

    const {
        general,
        login,
        getPortfolio,
        getMonthlyBalance,
        getPerformance,
        getWatchlist,
    } = useGeneral();
    const [data, setData] = useState([]);
    const { portfolio, monthlyBalances, performance, watchlist, user } = general;
    const [scoopsData, setScoopsData] = useState([])
    const [fundData, setFundsData] = useState([])
    useEffect(() => {
        console.log(market, "market")
        getMajorMarket();
        getNews();
        getTopPerformers();
        getTrendingStocks();
    }, []);



    useEffect(() => {
        getBrowseFilterValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketType]);
    const getScoops = async () => {
        const getScoops = await http_security.get(`securities/scoops`);
        setScoopsData(getScoops.data.data)
    }

    useEffect(() => {
        getScoops()
        getAnalystist()
        getFunds()
    }, [])



    const getAnalystist = async () => {
        const response = await axios.get('https://a3mouljsyse565yxk5psbxa5cu0isnoj.lambda-url.us-east-1.on.aws/?type=Upgrades', {
            headers: {
                'Content-Type': 'application/json',
            },
        });


        setData(response.data);
    }
    const getFunds = async () => {
        const responseData = await axios.get('https://api.knack.com/v1/pages/scene_261/views/view_497/records?page=1&rows_per_page=12', {
            headers: {
                'X-Knack-Application-Id': '5f97d01c7677ff0019a96885',
                'X-Knack-REST-API-Key': 'e35691fa-f90d-482a-a3f4-742a91b85167',
                'Content-Type': 'application/json',
            },
        });


        console.log(responseData, "responseDataresponseData")

        setFundsData(responseData.data.records)


    }
    const getUser = async () => {
        const getUser = await http.post(`order/orderType`);
        console.log(getUser.data, "9999----")
        setUserData(getUser.data)
    }

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        if (!portfolio.data) {
            return;
        }

        const cashValue = parseFloat(portfolio.data[0].amount, 10);
        let totalValue = cashValue;

        const newHoldingsData = [...portfolio.data[1].positions];
        // sort the positions by market value
        newHoldingsData.sort((a, b) => {
            return b.marketValue - a.marketValue;
        });

        const labels = newHoldingsData.map((item) => item.symbol);
        labels.unshift("Cash");

        // const data = newHoldingsData.map((item) => {
        //   totalValue += item.change;
        //   return item.change;
        // });
        // data.unshift(cashValue);

        newHoldingsData.length > 0 && newHoldingsData.map((item) => (
            totalValue += !item.position * (!item.marketPrice - !item.averageCost)

        ));


    }, [portfolio]);
    return (
        <div className="">
            <div className="passiv">
                <div className="tabled quoteManagement">
                    <Card bordered={false} className="criclebox tablespace mb-24 p-3">
                        <Row gutter={[24, 0]}>
                            <Col span={24} md={12} xl={12}>

                                <div className="Market-maincc">
                                    <div className="card-pay">
                                        <h2><span>$</span>{(parseFloat(portfolio.data[0].amount) + parseFloat(portfolio.data[1].amount)).toFixed(2)} </h2>
                                        <div className="card-logo"><img src={Logo} alt="" /></div>
                                        <p>{userData && userData.userDetails && userData.userDetails.userData && userData.userDetails.userData.ibkrLiveUserId}</p>
                                    </div>
                                    <div className="account-list">
                                        <ul>
                                            <li>
                                                <p>Cash</p>
                                                <h4>{portfolio.data[0].amount}</h4>
                                            </li>
                                            <li>
                                                <p>Invested</p>
                                                <h4>{portfolio.data[1].amount}</h4>
                                            </li>
                                            <li>
                                                <p>Available to Trade</p>
                                                <h4>{portfolio.data[0].amount}</h4>
                                            </li>

                                            <li className="text-sucses">
                                                <p>Open P/L</p>
                                                <h4>29.81</h4>
                                            </li>
                                            <li className="list-footer">
                                                <span>Important Disclosures</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </Col>
                            <Col span={24} md={12} xl={12}>

                                <div className="activity-section">
                                    <div className="headding-acc">
                                        <h3>Recent Activity</h3>
                                        <div className="view-btn-s">
                                            <a className="view-a-btn">View all</a>
                                        </div>
                                    </div>
                                    <div className="recent-activity">
                                        <ul>
                                            {
                                                userData && userData.order.length > 0 && userData.order.slice(0, 15).map((item) => (
                                                    item.actions == "buying_stocks" || item.actions == "selling_stocks" ?
                                                        <li>
                                                            <div className="re-left-menu">
                                                                <h6>{item.query_result}</h6>
                                                                <span>{item.actions == "buying_stocks" ? "Buy" : "Sell"} {item.stockNumber}</span>
                                                            </div>
                                                            <div className="re-right-menu">
                                                                <h6>{item.finalPrice.toFixed(2)}</h6>
                                                                {/* <span></span> */}
                                                            </div>
                                                        </li> : null
                                                ))

                                            }
                                            {/* <li>
                                            <div className="re-left-menu">
                                                <h6>IRBT</h6>
                                                <span>Sell 5</span>
                                            </div>
                                            <div className="re-right-menu">
                                                <h6>+66.15</h6>
                                                <span>5 days ago</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="re-left-menu">
                                                <h6>One Time Card Deposit</h6>
                                                <span>38.43aed Card Deposit.</span>
                                            </div>
                                            <div className="re-right-menu">
                                                <h6>10.00</h6>
                                                <span>6 days ago</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="re-left-menu">
                                                <h6>One Time Card Deposit</h6>
                                                <span>38.43aed Card Deposit.</span>
                                            </div>
                                            <div className="re-right-menu">
                                                <h6 className="text-sucses">10.00</h6>
                                                <span>6 days ago</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="re-left-menu">
                                                <h6>BITO</h6>
                                                <span>Buy 12</span>
                                            </div>
                                            <div className="re-right-menu">
                                                <h6 className="text-sucses">+0.53</h6>
                                                <span>1 week ago</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="re-left-menu">
                                                <h6>NYCB</h6>
                                                <span>Buy 12</span>
                                            </div>
                                            <div className="re-right-menu">
                                                <h6>NYCB</h6>
                                                <span>Buy 12</span>
                                            </div>
                                        </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>

                    <Row className="mt-5 mb-5" style={{ marginTop: "24px" }} gutter={24}>
                        <Col span={24} lg={16}>
                            <div className="card-headding">
                                <h2>Analysts</h2>
                                <div className="right-headding-bar">
                                    <TabPane>
                                        <Tabs>

                                        </Tabs>
                                    </TabPane>
                                    <Button className="btn-analist active">Upgrades</Button>
                                    <Button className="btn-analist">Downgrades</Button>
                                </div>
                                <div className="info-btn"><InfoCircleOutlined /></div>
                            </div>
                            <Row gutter={12} className=" ">
                                <Col span={24}>
                                    <div className="tital">
                                        <h4>Resources for you</h4>
                                    </div>
                                </Col>

                            </Row>
                            <Card>
                                <div className="annlysts-menu">
                                    <ul>
                                        {data.length > 0 && data.slice(0, 10).map((item) => (
                                            <li>
                                                <div className="analysts-list-items-left">
                                                    <h6>{item.brokerage}</h6>
                                                    <p>{item.company}</p>
                                                </div>
                                                <div className="analysts-list-items-right">
                                                    <h6>{item.ticker}</h6>
                                                    {/* <p>{item.priceTarget.spilt("»")[0]}</p> */}
                                                </div>
                                            </li>
                                        )
                                        )}


                                    </ul>
                                </div>
                            </Card>
                            <Col span={24}  >
                                <div className="card-headding">
                                    <div>
                                        <h2>Scoops</h2>
                                        <p>Stories from our content desk</p>
                                    </div>
                                    <div className="info-btn"><span>Disclaimer</span></div>
                                </div>
                                <Row gutter={24}>
                                    {scoopsData && scoopsData.length > 0 && scoopsData.map((item) => (
                                        <Col
                                            span={24}
                                            md={8}
                                        >
                                            <div className="resources-card-p">
                                                <Card style={{ cursor: "pointer" }}>
                                                    <CardBody className="resources-card-body card-home">
                                                        <div className="analysts-outer">
                                                            <img
                                                                src={`https://pasiv-data-kms.s3.amazonaws.com/scoopsArt/${item.img}.jpg`}
                                                            ></img>
                                                            <h5>{item.scoopTitle}</h5>
                                                        </div>
                                                        <p>{item.scoopText}</p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </Col>
                                    ))}


                                </Row>
                            </Col>
                            <Col span={24} className="mt-5"  >
                                <div className="card-headding">
                                    <div>
                                        <h2>Fund Managers</h2>
                                    </div>
                                    <div className="info-btn"><InfoCircleOutlined /></div>
                                </div>
                                <Card bordered={false} className="fund-cards" >
                                    {fundData && fundData.length > 0 ? fundData.map((item) => (
                                        <div className="fuund-header">
                                            <div className="profile-user">
                                                <img src={item.field_1115_raw ? item.field_1115_raw.url : null} />
                                            </div>
                                            <div className="user-details">
                                                <h5>{item.field_1106_raw}</h5>
                                                <h6>{item.field_1105_raw}</h6>
                                                <p>Recorded <span>{item.field_1109}</span></p>
                                            </div>
                                        </div>
                                    ))
                                        : null}
                                    {/* <div className="fund-body-text">
                                    <p>May 20: Amidst a backdrop of climatic chaos and dwindling resources, the world faces a potential "chocolate armageddon" with cocoa supplies threatening to run dry. Hedge fund maestro Pierre Andurand shines a light on this looming crisis, pointing out a confluence of adverse weather, climate change impacts, fertilizer shortages, and rampant plant diseases that are decimating cocoa production. His observations come as cocoa prices hit an unprecedented $12,000 per ton, with his hedge fund strategically capitalizing on this surge through early investments in cocoa futures. Andurand warns that</p>
                                </div> */}
                                </Card>
                            </Col>
                            <Col span={24} className="mt-5"  >
                                <div className="card-headding">
                                    <div className="pic-section">
                                        <h2>Big Picture</h2>
                                        <span>S&P 500 Futures</span>
                                    </div>
                                    <div className="info-btn"><InfoCircleOutlined /></div>
                                </div>
                                <Row gutter={24}>
                                    <Col span={24} lg={8}>
                                        <Card bordered={false} className="fund-cards" >
                                            <div className="picture-header">
                                                <div className="picture-user">
                                                    <img src={Banner} />
                                                </div>
                                                <div className="pics-details">
                                                    <p>16/05/2024</p>
                                                    <h6>Markets rally to all time highs on good earnings across sectors</h6>
                                                    <div className="picture-body-text">
                                                        <p>MSP500 futures traded to an all time high of 5337.25 in Wednesday's session, after the latest inflation report showed price pressures moderating, bucking the trend of hotter-than- expected readings. Strong earnings from bellweather companies across the board have also given traders reasons to be optimistic about higher stock prices. The return of Keith Gill to social media this week also led to a frenzy in meme stock shares Gamestop and ...</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>
                                    </Col>
                                    <Col span={24} lg={8}>
                                        <Card bordered={false} className="fund-cards" >
                                            <div className="picture-header">
                                                <div className="picture-user">
                                                    <img src={Banner} />
                                                </div>
                                                <div className="pics-details">
                                                    <p>16/05/2024</p>
                                                    <h6>Markets rally to all time highs on good earnings across sectors</h6>
                                                    <div className="picture-body-text">
                                                        <p>MSP500 futures traded to an all time high of 5337.25 in Wednesday's session, after the latest inflation report showed price pressures moderating, bucking the trend of hotter-than- expected readings. Strong earnings from bellweather companies across the board have also given traders reasons to be optimistic about higher stock prices. The return of Keith Gill to social media this week also led to a frenzy in meme stock shares Gamestop and ...</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>
                                    </Col>
                                    <Col span={24} lg={8}>
                                        <Card bordered={false} className="fund-cards" >
                                            <div className="picture-header">
                                                <div className="picture-user">
                                                    <img src={Banner} />
                                                </div>
                                                <div className="pics-details">
                                                    <p>16/05/2024</p>
                                                    <h6>Markets rally to all time highs on good earnings across sectors</h6>
                                                    <div className="picture-body-text">
                                                        <p>MSP500 futures traded to an all time high of 5337.25 in Wednesday's session, after the latest inflation report showed price pressures moderating, bucking the trend of hotter-than- expected readings. Strong earnings from bellweather companies across the board have also given traders reasons to be optimistic about higher stock prices. The return of Keith Gill to social media this week also led to a frenzy in meme stock shares Gamestop and ...</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="mt-5"  >
                                <div className="card-headding">
                                    <div className="pic-section">
                                        <h2>Community</h2>
                                    </div>
                                </div>
                                <div className="add-user-select">
                                    <UserAddOutlined />
                                    <Input placeholder="Add or Remove Friends" />
                                </div>
                                <Row gutter={24}>
                                    <Col span={24} lg={8}>
                                        <Card bordered={false} className="fund-cards" >
                                            <div className="picture-header">
                                                <div className="picture-user">
                                                    <img src={User01} />
                                                </div>
                                                <div className="pics-details">
                                                    <h6>Kuldip</h6>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col span={24} lg={8}>
                                        <Card bordered={false} className="fund-cards" >
                                            <div className="picture-header">
                                                <div className="picture-user">
                                                    <img src={User01} />
                                                </div>
                                                <div className="pics-details">
                                                    <h6>Kuldip</h6>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col span={24} lg={8}>
                                        <Card bordered={false} className="fund-cards" >
                                            <div className="picture-header">
                                                <div className="picture-user">
                                                    <img src={User01} />
                                                </div>
                                                <div className="pics-details">
                                                    <h6>Kuldip</h6>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col span={24} lg={8}>
                            <Card>
                                <div className="right-marcket-section">
                                    <div className="pros-headding">
                                        <img width={20} src={ProgressIcon} /><h6>Your Progress</h6>
                                    </div>
                                    <div className="pros-body">
                                        <Progress percent={30} />
                                        <span className="present-text">36%</span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5 mb-5" gutter={[24, 0]}>
                        <Col span={24} md={24} xl={24}>
                            <div className="header-ss">
                                <h4>Settings</h4>
                                <div>
                                    <img width={50} src={UserIcon} />
                                </div>
                            </div>
                            <div className="profile-body">
                                <div className="left-do-cc">
                                    <h6>Vinay gokaldas</h6>
                                    <span>2CT00312</span>
                                </div>
                                <div className="cc-body-right">
                                    <h6>Basic</h6>
                                </div>
                            </div>
                            <div className="linebar-hh">
                                <img src={UserIcon} /><h4>Profile</h4>
                            </div>
                            <div className="profile-user-list">
                                <ul>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>Update Contact Details</h6>
                                            <span>Change your phone, email or address</span>
                                        </div>
                                        <div className="cc-body-right">
                                            <img src={RightIcon} />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>OTP Notification: Via SMS</h6>
                                            <span>Tap here to switch between SMS & Email</span>
                                        </div>

                                    </li>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>Disable Community</h6>
                                            <span>Turn off portfolio sharing with others</span>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="linebar-hh">
                                <img src={ChartIcon} /><h4>Brokerage</h4>
                            </div>
                            <div className="profile-user-list">
                                <ul>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>Nominee Details</h6>
                                            <span>Assign a beneficiary to your wealth</span>
                                        </div>
                                        <div className="cc-body-right">
                                            <img src={RightIcon} />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>OTP Notification: Via SMS</h6>
                                            <span>Tap here to switch between SMS & Email</span>
                                        </div>
                                        <div className="cc-body-right">
                                            <img src={RightIcon} />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>Disable Community</h6>
                                            <span>Turn off portfolio sharing with others</span>
                                        </div>
                                        <div className="cc-body-right">
                                            <img src={RightIcon} />
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="linebar-hh">
                                <img src={UserIcon} /><h4>Billing</h4>
                            </div>
                            <div className="profile-user-list">
                                <ul>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>Update Contact Details</h6>
                                            <span>Change your phone, email or address</span>
                                        </div>
                                        <div className="cc-body-right">
                                            <img src={RightIcon} />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>OTP Notification: Via SMS</h6>
                                            <span>Tap here to switch between SMS & Email</span>
                                        </div>

                                    </li>
                                    <li>
                                        <div className="left-list-menu">
                                            <h6>Disable Community</h6>
                                            <span>Turn off portfolio sharing with others</span>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 mb-5">
                        <div className="mainWraper">
                            <div className="requestQuote sectionPading">
                                <div class="chatMainOuter">
                                    <div class="chatMainInner bgDesign p-0 overflow-hidden h-100">
                                        <div class="row h-100 mx-0">
                                            <div class="col-lg-24 col-xl-24 h-100 px-0">
                                                <div class="chatingSide active h-100">
                                                    <div class="row flex-column flex-nowrap h-100">
                                                        <div class="col-12">
                                                            <div class="chatHeader online p-sm-3 p-2 border-start" style={{ padding: '10px' }}>
                                                                <div class="clientPro d-flex align-items-center gap-2" style={{ display: 'flex' }}>
                                                                    <div class="detail">
                                                                        <div class="name">Chat</div>
                                                                    </div>
                                                                    <div className="header-search">
                                                                        <SearchOutlined />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 flex-fill h-100 overflow-hidden">
                                                            <div class="chatOuter overflow-x-hidden overflow-y-auto h-100 p-3 d-flex flex-column-reverse">
                                                                <div class="msgMain send row mx-0 gap-2">
                                                                    <div class="col msgCol px-0">
                                                                        <div class="msg txtMsg overflow-hidden mb-2">
                                                                            <div class="txt">Thank you</div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="msgMain resive row mx-0 gap-2">
                                                                    <div class="col-auto imgCol px-0">
                                                                        <div class="imgOuter rounded-circle overflow-hidden">
                                                                            <img class="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col msgCol px-0">
                                                                        <div class="msg txtMsg overflow-hidden mb-2">
                                                                            <div class="txt">Ok Done</div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="msgDate text-center py-1 d-flex align-items-center justify-content-center"><div class="date px-3">Today</div></div>
                                                                <div class="msgMain send row mx-0 gap-2">
                                                                    <div class="col msgCol px-0">
                                                                        <div class="msg txtMsg overflow-hidden mb-2">
                                                                            <div class="txt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure cum corrupti maxime.</div>
                                                                        </div>
                                                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                    </div>
                                                                </div>

                                                                <div class="msgMain resive row mx-0 gap-2">
                                                                    <div class="col-auto imgCol px-0">
                                                                        <div class="imgOuter rounded-circle overflow-hidden">
                                                                            <img class="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col msgCol px-0">
                                                                        <div class="msg txtMsg overflow-hidden mb-2 p-0">
                                                                            <div class="txt">
                                                                                <div className="chat-card">
                                                                                    <div className="chat-header-c">
                                                                                        <Button className="btn-chat-card">Buy Order</Button>
                                                                                        <span className="chat-card-out">ANTX</span>
                                                                                    </div>
                                                                                    <div className="chat-body-t">
                                                                                        <div className="chat-body-l">
                                                                                            <h6>New York Community Bancorp, Inc. × 12.00</h6>
                                                                                            <p>SHARES</p>
                                                                                        </div>
                                                                                        <div className="chat-body-r">
                                                                                            <p>PRICE PER SHARE</p>
                                                                                            <h6>12.00</h6>
                                                                                            <p>US DOLLAR</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="chat-body-footer">
                                                                                        <CheckCircleOutlined />
                                                                                        <h6> ADDED TO PORTFOLIO</h6>
                                                                                        <p>Executed at 2024-05-15 11:58 PM</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="msgMain send row mx-0 gap-2">
                                                                    <div class="col msgCol px-0">
                                                                        <div class="msg txtMsg overflow-hidden mb-2">
                                                                            <div class="txt">Hello john, how are you?</div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>

                                                                        <div class="msg docMsg overflow-hidden mb-2">
                                                                            <div className="document d-flex align-items-center gap-2">
                                                                                <div class="docIcon overflow-hidden">
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M19.5 9H13.5V3H4.5V21H19.5V9ZM18.879 7.5L15 3.621V7.5H18.879ZM3.75 1.5H15L21 7.5V21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5H3.75C3.55109 22.5 3.36032 22.421 3.21967 22.2803C3.07902 22.1397 3 21.9489 3 21.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5ZM7.5 12H16.5V13.5H7.5V12ZM7.5 7.5H11.25V9H7.5V7.5ZM7.5 16.5H16.5V18H7.5V16.5Z" fill="#121212" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="documentName">New code.pdf</div>
                                                                            </div>
                                                                            <div class="txt">Hello john, how are you?</div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="msgMain resive row mx-0 gap-2">
                                                                    <div class="col-auto imgCol px-0">
                                                                        <div class="imgOuter rounded-circle overflow-hidden">
                                                                            <img class="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col msgCol px-0">
                                                                        <div class="msg txtMsg overflow-hidden mb-2">
                                                                            <div class="txt">Hello john, how are you?</div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>
                                                                        <div class="msg docMsg overflow-hidden mb-2">
                                                                            <div className="document d-flex align-items-center gap-2">
                                                                                <div class="docIcon overflow-hidden">
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M19.5 9H13.5V3H4.5V21H19.5V9ZM18.879 7.5L15 3.621V7.5H18.879ZM3.75 1.5H15L21 7.5V21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5H3.75C3.55109 22.5 3.36032 22.421 3.21967 22.2803C3.07902 22.1397 3 21.9489 3 21.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5ZM7.5 12H16.5V13.5H7.5V12ZM7.5 7.5H11.25V9H7.5V7.5ZM7.5 16.5H16.5V18H7.5V16.5Z" fill="#121212" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="documentName">New code.pdf</div>
                                                                            </div>
                                                                            <div class="txt">Hello john, how are you?</div>
                                                                            <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="msgDate text-center py-1 d-flex align-items-center justify-content-center"><div class="date px-3">March 21, 2023</div></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="chatFooter p-sm-3 p-2">
                                                                <form class="" role="search">
                                                                    <div class="position-relative d-flex align-items-center gap-sm-3 gap-2">
                                                                        <div class="fileUpload">
                                                                            <input type="file" class="form-control d-none shadow-none border-0" id="uploadImg" />
                                                                            <label for="uploadImg" class="fileAtechLabel d-flex align-items-center justify-content-center"><img src={chatFiles} alt="" /></label>
                                                                        </div>
                                                                        <InputGroup className="position-relative">
                                                                            <Form.Control type="text" className="rounded-1 pe-5" placeholder="Type..." />
                                                                        </InputGroup>
                                                                        <div class="footerBtnGroup d-flex align-items-center position-absolute gap-2">
                                                                            <button type="button" class="btn border-0 shadow-none btnMsgSend d-flex align-items-center justify-content-center">
                                                                                <img className="" src={msgSendIcon} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="emptyState h-100 d-none d-lg-none align-items-center justify-content-center">
                                                    <div className="emptystateInner">
                                                        <div className="emptyStateImg">
                                                            <img className="" src={chatEmptyState} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </Row>
                </div>
            </div>


        </div>
    );
};

export default Account;
