import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  mainLoader: false,
};

const SettingsReducer = createSlice({
  name: "setting",
  initialState,
  reducers: {
    updateCountries: (state, action) => {
      state.countries = action.payload;
    },
    updateMainLoader: (state, action) => {
      state.mainLoader = action.payload;
    },
  },
});

export const settingsActions = { ...SettingsReducer.actions };

export default SettingsReducer.reducer;
