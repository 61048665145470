import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import AppContainer from "../components/Container/AppContainer";
import { selectUser } from "../redux/selectors";
import CONST from "../shared/CONST";
import { getLocalStorageItem } from "../shared/utils";

const SiteRoute = ({ component: Component, type, path, exact }) => {
  const token = getLocalStorageItem(CONST.JWT_TOKEN);
  const user = useSelector(selectUser, shallowEqual);

  // console.log({ type, path, exact, user });

  useEffect(() => {
    //  hide recaptcha-container for authenticated users
    if (user.data || token) {
      document.getElementById("recaptcha-container").style.visibility =
        "hidden";
    }
  }, [user.data, token]);

  return (
    <Route
      exact={exact}
      render={(props) => {
        if (type === "public")
          return user.otpVerified ? (
            // Landing page for verified/loggedIn users
            <Redirect to="/home" />
          ) : (
            <Component {...props} />
          );
        else if (type === "private") {
          // todo: check if token is present
          // and redirect to login if not
          return token || user.data ? (
            <AppContainer>
              <Component {...props} />
            </AppContainer>
          ) : (
            <Redirect to="/" />
          );
        }
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export default SiteRoute;

SiteRoute.defaultProps = {
  exact: false,
  type: "public",
};

SiteRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string,
  exact: PropTypes.bool,
  type: PropTypes.string,
};
