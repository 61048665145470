import { createSelector } from "@reduxjs/toolkit";

// Settings
const selectSetting = (state) => state.setting;

export const selectCountries = createSelector(
  selectSetting,
  (setting) => setting.countries
);

// General

const selectGeneral = (state) => state.general;

export const selectUser = createSelector(
  selectGeneral,
  (general) => general.user
);

// export const selectToken = createSelector([selectUser], (user) => user.token);

// export const selectCurrentUser = createSelector(
//   [selectUser],
//   (user) => user.currentUser
// );

// export const selectIsUserLoading = createSelector(
//   [selectUser],
//   (user) => user.isLoading
// );
