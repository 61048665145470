import { useRef, useState } from "react";
import TinySlider from "tiny-slider-react";
import { getFormattedValue } from "../shared/utils";
import Ticker from "./Ticker";
import { ReactComponent as DownButtonIcon } from "../assets/icons/down_btn.svg";

const sliderSettings = {
  // lazyload: true,
  items: 4,
  axis: "vertical",
  swipeAngle: false,
  speed: 400,
  mouseDrag: false,
  touch: false,
  loop: true,
  nav: false,
  controls: false,
  slideBy: 2,
};

const MarketTableRow = ({
  ticker,
  changes,
  changesPercentage,
  companyName,
}) => {
  const [isAvailable, setIsAvailable] = useState(true);

  const handleImageError = (e) => {
    setIsAvailable(false);
  };

  return (
    isAvailable && (
      <div className="MarketTable_row">
        <img
          className="MarketTable_row_logo"
          src={`https://fmpcloud.io/image-stock/${ticker}.png`}
          alt={ticker}
          onError={handleImageError}
        />
        <Ticker
          classes="MarketTable_row_ticker"
          item={{
            symbol: ticker,
          }}
        />
        <p className="MarketTable_row_companyName">{companyName}</p>

        <div className="MarketTable_row_stats">
          <img
            className="MarketTable_row_stats_chart"
            src={`https://api.pasiv.io/api/v1/marketChart/${ticker}.png`}
            alt={ticker}
            onError={handleImageError}
          />
          <p
            className={`MarketTable_row_stats_change ${
              changes > 0 ? "up" : "down"
            }`}
          >
            {changes > 0 && "+"}
            {getFormattedValue(changes)} ({getFormattedValue(changesPercentage)}
            %)
          </p>
        </div>
      </div>
    )
  );
};

const MarketTable = ({ data }) => {
  const tableSliderRef = useRef();

  const handleNext = () => tableSliderRef.current.slider.goTo("next");

  return (
    <div className="MarketTable">
      {data?.length > 0 && (
        <>
          <TinySlider settings={sliderSettings} ref={tableSliderRef}>
            {data.map((item) => (
              <MarketTableRow
                key={item.ticker || item.symbol}
                ticker={item.ticker || item.symbol}
                changes={item.changes || item.change}
                changesPercentage={item.changesPercentage}
                companyName={item.companyName || item.name}
              />
            ))}
          </TinySlider>

          <DownButtonIcon className="next-btn" onClick={handleNext} />
        </>
      )}
    </div>
  );
};

export default MarketTable;
