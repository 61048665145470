import CONST from "../shared/CONST";
import logoRoundImg from "../assets/images/logo_round.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generalActions } from "../redux/slices/generalSlice";

const ComingSoon = () => {
  const [countdown, setCountdown] = useState("");
  // return hh:mm:ss countdown to launch

  const dispatch = useDispatch();

  const getCountdown = () => {
    const now = new Date();
    const launchDate = new Date(CONST.LAUNCH_DATE);
    const diff = launchDate.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getCountdown());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const logout = () => dispatch(generalActions.logout());

  // temp logic
  return (
    <div className="ComingSoon">
      <a className="topleft" href="/">
        <img className="logo" src={logoRoundImg} alt="pasiv financial" />
      </a>
      <button onClick={logout} className="topright">
        Logout
      </button>
      <div className="middle">
        <h1>COMING SOON</h1>
        <hr />
        <p className="msg">
          We are currently working on the next generation of Pasiv. We will be
          launching soon. Stay tuned!
        </p>
        <p className="countdown">{countdown}</p>
      </div>
      <div className="bottomright">
        <p>Something about the site/service or links to socials</p>
      </div>
    </div>
  );
};

export default ComingSoon;
