import { useEffect, useState } from "react";
import { useMarket } from "../hooks/useStore";
import MajorMarketChart from "./Charts/MajorMarket.chart";
import { ReactComponent as UpTrendIcon } from "../assets/icons/up_trend.svg";
import { ReactComponent as DownTrendIcon } from "../assets/icons/down_trend.svg";
import { getFormattedValue } from "../shared/utils";

const MajorMarketSection = () => {
  const { market } = useMarket();
  const {
    majorMarket: { data, isLoaded },
  } = market;

  const [majorMarketData, setMajorMarketData] = useState([]);

  useEffect(() => {
    if (isLoaded) {
      // sort data by market index
      const sortedData = [...data].sort((a, b) => a.index - b.index);
      setMajorMarketData(sortedData);
    }
  }, [data, isLoaded]);

  return (
    isLoaded && (
      <div className="MajorMarketSection">
        {majorMarketData.map((item) => {
          const newItem = {
            ...item,
            chart: {
              labels: item.data2,
              data: [...item.data1].reverse(),
              symbol: item.symbol,
              target: item.open,
              change: getFormattedValue(item.changePerc),
            },
            title: item.symbol,
            price: getFormattedValue(item.price),
            change: getFormattedValue(item.changePerc),
          };

          return (
            <div key={newItem.symbol} className="MajorMarketSection_item">
              <div className="MajorMarketSection_item_header">
                <div className="MajorMarketSection_item_header_left">
                  <span>{newItem.symbol}</span>
                  {/* <span>Futures</span> */}
                </div>

                <div className="MajorMarketSection_item_header_right">
                  <span className="price">${newItem.price}</span>
                  <span
                    className={`change ${newItem.change > 0 ? "up" : "down"}`}
                  >
                    {newItem.change > 0 ? <UpTrendIcon /> : <DownTrendIcon />}
                    {newItem.change}%
                  </span>
                </div>
              </div>

              <div className="MajorMarketSection_item_body">
                <MajorMarketChart
                  className="MajorMarketSection_item_body_chart"
                  chart={newItem.chart}
                />
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default MajorMarketSection;
