import axios from "axios";
import CONST from "../shared/CONST";
import { getLocalStorageItem } from "../shared/utils";

const api = axios.create({
  baseURL: "https://api.pasiv.io/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

const api_security = axios.create({
  baseURL: "https://securities.pasiv.io/",
  headers: {
    "Content-Type": "application/json",
  },
});

// const api_fmp = axios.create({
//   baseURL: "https://fmpcloud.io/api/v3/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const api_fmg = axios.create({
  baseURL: "https://financialmodelingprep.com/api/v3/",
  headers: {
    "Content-Type": "application/json",
  },
});

const getHeaders = () => {
  const jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6Iis5NzE1MjI0Nzk5MTEiLCJpc0RlbW8iOmZhbHNlLCJjaGF0SWQiOiJkYjk4MmY3NC04NmI5LTQwMDEtOGM5ZC04M2FlYzZlNWI2YmMiLCJpYXQiOjE3MTA3NzE3Mjd9.1SjdCxcl43r2PqCRL96C0rOFzhAmsXnE9hLMneEUqCg';
  // console.log({ JWT_TOKEN: jwt });
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
      "x-access-token": "eyJhbGciOiJQUzM4NCIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTg1MTk5MyIsIm5hbWUiOiJBeWVrYSIsImFkbWluIjp0cnVlLCJpYXQiOjE1MTYyOTg5MjJ9.LagcUF764UEHzHnhv9C57jDUrcYzOoByHi7uY2fB-qWjB1vRYDJJ7cGrMhf_aaxutxf3npQFVpmVI-xcBIjCNjotJ8KiNM7wVMuY4keiS9qWmOyV3vKDCsotgMiysvwNzA37gt2JX79ljmXXz8ix99kv0EgyHB8FMd5rFw6gccEVDc7GD_mvoJp--ssgGnZpugpqIHlY8oY4VvTthTS8V_SovdO_VMXEhHiyBPCTzZebqd3OQeDdT_Ti47fQ99es_ZlAHcrd0GtkaVh135V2UMc9OSKxcuHx6aIBFS4wjgLlvG3UfqBtjmjy5TRkYY4tqIheGCtwx7tnqDr1Lkt2fA",
    },
  };
};

// api.interceptors.request.use((request) => {
//   // console.log("Starting Request :\n", JSON.stringify(request, null, 2));
//   return request;
// });

// api.interceptors.response.use((response) => {
//   // console.log("Response :\n", JSON.stringify(response, null, 2));
//   return response;
// });

// // error
// api.interceptors.response.use(null, (error) => {
//   if (error) {
//     // console.log("Error :\n", JSON.stringify(error, null, 2));
//     console.error(error);
//   }

//   return Promise.reject(error);
// });

const http = {
  get: (url, config) =>
    api.get(url, {
      ...getHeaders(),
      ...config,
    }),
  post: (url, body, config) =>
    api.post(url, body, {
      ...getHeaders(),
      ...config,
    }),
  put: (url, body, config) =>
    api.put(url, body, {
      ...getHeaders(),
      ...config,
    }),
  delete: (url, config) =>
    api.delete(url, {
      ...getHeaders(),
      ...config,
    }),
};

export const http_security = {
  get: (url, config) =>
    api_security.get(url, {
      ...getHeaders(),
      ...config,
    }),
  post: (url, body, config) =>
    api_security.post(url, body, {
      ...getHeaders(),
      ...config,
    }),
  put: (url, body, config) =>
    api_security.put(url, body, {
      ...getHeaders(),
      ...config,
    }),
  delete: (url, config) =>
    api_security.delete(url, {
      ...getHeaders(),
      ...config,
    }),
};

// export const http_fmp = {
//   get: (url, config) =>
//     api_fmp.get(url, {
//       ...getHeaders(),
//       ...config,
//     }),
// };

export const http_fmg = {
  get: (url, config) =>
    api_fmg.get(url, {
      ...getHeaders(),
      ...config,
    }),
};

export default http;
