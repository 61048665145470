import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as Select from "@radix-ui/react-select";
import Input from ".";
import { useEffect } from "react";
import { debounce } from "../../shared/utils";
import { useSetting } from "../../hooks/useStore";

// #Used `https://restcountries.com/v3.1/all` to get all the details about countries

const PhoneInput = React.forwardRef(
  (
    {
      name,
      label,
      type,
      placeholder,
      value,
      onChange,
      error,
      leftDefault,
      onLeftChange,
      styles,
      ...rest
    },
    ref
  ) => {
    const {
      setting: { countries },
      updateCountries,
    } = useSetting();

    const [localCountries, setLocalCountries] = useState(countries || []);
    const searchInputRef = React.useRef(null);

    const handleDialCodeChange = (val) => onLeftChange(val);

    const debouncedFilterByName = debounce((name) => {
      if (name.length > 0) {
        const filtered = countries.filter((country) => {
          // constructing search string
          const searchString =
            country.altSpellings.join("") +
            country.name.common +
            country.dialCode;

          return searchString.toLowerCase().includes(name.toLowerCase());
        });
        setLocalCountries(filtered);
        console.log({ filtered });
      } else {
        setLocalCountries(countries);
      }
    }, 600);

    useEffect(() => {
      if (countries.length > 0) {
        setLocalCountries(countries);
        return;
      }

      fetch("https://restcountries.com/v3.1/all")
        .then((res) => res.json())
        .then((data) => {
          if (countries.length === 0) {
            //remove any country without a dial code : "Heard Island and McDonald Islands"
            const filteredCountries = data.filter(
              (country) =>
                country.name.common !== "Heard Island and McDonald Islands"
            );
            //add dial code to each country
            const countriesWithDialCode = filteredCountries.map((country) => {
              return {
                ...country,
                dialCode:
                  country.idd.root +
                  (country.idd?.suffixes?.length === 1
                    ? country.idd.suffixes[0]
                    : ""),
              };
            });

            updateCountries(countriesWithDialCode);
            setLocalCountries(countriesWithDialCode);
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    return (
      <div className="App-Input App-Input-phone">
        {label && <label htmlFor={name}>{label}</label>}
        <div className="App-Input_input-container">
          <Select.Root
            as={React.Fragment}
            onOpenChange={(value) => {
              if (!value) {
                setLocalCountries(countries);
              }

              // searchInputRef.current?.focus();
              // console.log({ searchInputRef });
            }}
            onValueChange={(value) => handleDialCodeChange(value.split("_")[0])}
          >
            <Select.Trigger
              style={{ padding: 0, outline: "none", cursor: "pointer" }}
            >
              <Select.Value aria-label={leftDefault}>
                <span className="left-default">{leftDefault}</span>
              </Select.Value>
            </Select.Trigger>

            <Select.Portal>
              <Select.Content className="App-Input_combo-box">
                {/* <Select.ScrollUpButton>UP</Select.ScrollUpButton> */}
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, y: -10, scale: 0.8 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, y: -10, scaleY: 0.8 }}
                    transition={{ type: "spring", duration: 0.5 }}
                    className="App-Input_combo-box_content"
                  >
                    <div className="search">
                      <Input
                        ref={searchInputRef}
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => {
                          debouncedFilterByName(e.target.value);
                          // e.preventDefault();
                          // e.stopPropagation();
                        }}
                      />
                    </div>

                    <Select.Viewport className="combo-box_viewport">
                      {localCountries.length > 0 ? (
                        localCountries.map(
                          ({ name, flag, dialCode }, index) => (
                            <React.Fragment key={`${name.common}-${index}`}>
                              <Select.Group className="combo-box_group">
                                <Select.Item
                                  value={`${dialCode}_${name.common}`}
                                  className="combo-box_group_item"
                                >
                                  <Select.ItemText className="comb-box_group_item_text">
                                    <span className="flag-icon">{flag}</span>
                                    <span className="country">
                                      {name.common}
                                    </span>
                                    <span className="dial-code">
                                      {dialCode}
                                    </span>
                                  </Select.ItemText>
                                </Select.Item>
                              </Select.Group>
                              {localCountries.length - 1 !== index && (
                                <Select.Separator className="combo-box_separator" />
                              )}
                            </React.Fragment>
                          )
                        )
                      ) : (
                        <div className="no-results">No results found</div>
                      )}
                    </Select.Viewport>
                  </motion.div>
                </AnimatePresence>
              </Select.Content>
            </Select.Portal>
          </Select.Root>

          <input
            // ref={ref}
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={leftDefault ? "left-present" : ""}
            {...rest}
          />
        </div>
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10, scale: 0.8 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scaleY: 0.8 }}
              transition={{ type: "spring", duration: 0.8 }}
              className="App-Input_error"
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default PhoneInput;
