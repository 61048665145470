import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../services/authService";
import mainService from "../../services/mainService";
import CONST from "../../shared/CONST";
import { getDocFromDB } from "../../shared/firebase";
import {
  createAlert,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../../shared/utils";

const initialState = {
  notifications: [],
  user: {
    isExists: false,
    otpVerified: false,
    data: null,
    // token
  },
  pasivUser: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },

  portfolio: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },

  monthlyBalances: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },

  performance: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },

  watchlist: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
};

const login = createAsyncThunk("general/login", async ({ data, callback }) => {
  const response = await authService.login(data);
  if (response.data.success) {
    setLocalStorageItem(CONST.JWT_TOKEN, response.data.result.token);
    callback && callback();
    return response.data.result;
  }

  throw new Error(response.data.message);
});

const getPortfolio = createAsyncThunk("general/getPortfolio", async () => {
  const response = await mainService.getPortfolio();
  if (response.data.success) {
    return response.data.result;
  }

  throw new Error(response.data.message);
});

const getMonthlyBalance = createAsyncThunk(
  "general/getMonthlyBalance",
  async (_, thunkAPI) => {
    const { pasivUser } = thunkAPI.getState().general;
    const id = "5e58e6e432a39867ab4c1139";
    if (!id) {
      return;
    }

    const response = await mainService.getMonthlyBalance(id);
    if (response.data.message === "success") {
      return response.data.data;
    }

    throw new Error(response.data.message);
  }
);

const getPerformance = createAsyncThunk(
  "general/getPerformance",
  async (range, thunkAPI) => {
    const { pasivUser } = thunkAPI.getState().general;
    const id = pasivUser.data.userId;
    const data = {
      userId: "5e58e6e432a39867ab4c1139",
      interval: range,
      isRaw: false,
    };

    if (!id) {
      return;
    }
    // console.log(data, "data")

    const response = await mainService.getPerformance(data);
    console.log(response.status, "response")
    if (response.status == 200) {
      console.log(response.data, "response111111111111")
      return response.data.data;
    }

    throw new Error(response.data.message);
  }
);

const getWatchlist = createAsyncThunk("general/getWatchlist", async () => {
  const tempRes = await getDocFromDB("Users", "4K57Oserkn7YCdsQqMAk");
  const res = await mainService.getTickersPrice(tempRes.watchList);

  if (res.status === 200) {
    return res.data.data;
  }

  throw new Error(res.data.message || "Something went wrong");
});

// customReducers
const fulfilledReducer =
  (key) =>
    (state, { payload }) => {
      state[key].isLoading = false;
      state[key].isLoaded = true;
      state[key].data = payload;
    };

const rejectionReducer =
  (key) =>
    (state, { error }) => {
      state[key].isLoading = false;
      state[key].isLoaded = true;
      createAlert({
        type: "error",
        message: error.message || "Something went wrong",
      });
    };

const pendingReducer = (key) => (state) => {
  state[key].isLoading = true;
};

const GeneralReducer = createSlice({
  name: "general",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = {
        isExists: true,
        otpVerified: true,
        data: action.payload,
      };
    },
    logout: (state) => {
      // todo: firebase logout?
      removeLocalStorageItem(CONST.JWT_TOKEN);
      createAlert({
        type: "info",
        message: "Logged out successfully",
      });

      return initialState;
    },
  },
  extraReducers: {
    [login.fulfilled]: fulfilledReducer("pasivUser"),
    [login.pending]: pendingReducer("pasivUser"),
    [login.rejected]: rejectionReducer("pasivUser"),

    [getPortfolio.fulfilled]: fulfilledReducer("portfolio"),
    [getPortfolio.pending]: pendingReducer("portfolio"),
    [getPortfolio.rejected]: rejectionReducer("portfolio"),

    [getMonthlyBalance.fulfilled]: fulfilledReducer("monthlyBalances"),
    [getMonthlyBalance.pending]: pendingReducer("monthlyBalances"),
    [getMonthlyBalance.rejected]: rejectionReducer("monthlyBalances"),

    [getPerformance.fulfilled]: fulfilledReducer("performance"),
    [getPerformance.pending]: pendingReducer("performance"),
    [getPerformance.rejected]: rejectionReducer("performance"),

    [getWatchlist.fulfilled]: fulfilledReducer("watchlist"),
    [getWatchlist.pending]: pendingReducer("watchlist"),
    [getWatchlist.rejected]: rejectionReducer("watchlist"),
  },
});

export const generalActions = {
  ...GeneralReducer.actions,
  login,
  getPortfolio,
  getMonthlyBalance,
  getPerformance,
  getWatchlist,
};

export default GeneralReducer.reducer;
