import React, { useState } from "react";
import Input from "../Input";

import { ReactComponent as DownIcon } from "../../assets/icons/down.svg";
import SidebarItem from "./SidebarItem";
import PopoverWrapper from "../Popover";
import { useGeneral } from "../../hooks/useStore";
import { useEffect } from "react";
import { getFormattedValue } from "../../shared/utils";
import http from "../../services";

const DropdownContent = ({ onLogout }) => {
  const profileItems = [
    {
      onClick: onLogout,
      text: "Logout",
    },
  ];

  return (
    <div className="dropdown-content">
      {profileItems.map((item, index) => (
        <SidebarItem
          path={item.path}
          leftIcon={item.leftIcon}
          rightIcon={item.rightIcon}
          text={item.text}
          onClick={item.onClick}
          key={index}
        />
      ))}
    </div>
  );
};

const Header = () => {
  const [portfolioValue, setPortfolioValue] = useState(0);
  const { general, logout } = useGeneral();
  const[userData,setUserData]= useState()
  const onLogout = logout;

  useEffect(() => {
    let tempValue = 0;
    general?.portfolio?.data?.forEach(
      (item) => (tempValue += Number(item.amount))
    );

    setPortfolioValue(tempValue);
  }, [general.portfolio]);

const getUser = async () =>{
  const getUser = await http.post(`order/orderType`);
  console.log(getUser.data,"9999----")
   setUserData(getUser.data.userDetails)
}
  
  useEffect( ()=>{
    getUser()
  },[])

  return (
    <>
      <div id="header" className="Header">
        <div className="Header_left">
          <div className="searchbox">
            <Input
              // ref={searchInputRef}
              // leftDefault={<SignoutIcon />}
              type="text"
              placeholder="Search company or fund"
              onChange={(e) => {
                //   debouncedFilterByName(e.target.value);
                // e.preventDefault();
                // e.stopPropagation();
              }}
            />
          </div>
        </div>
        <div className="Header_right">
          <div className="portfolio-value">
            <span className="text">Portfolio value</span>
            <span className="value">
              {getFormattedValue(portfolioValue)} USD
            </span>
          </div>
          <img
            src={`${userData&&userData.userData &&userData.userData.profilePicture ?userData.userData.profilePicture :'http://lh4.googleusercontent.com/-e0FNv7UBhFg/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnrW18DJHzG2Vfw1VrSqwJrneGu0g/s88-c-k-c0x00ffffff-no-rj-mo/photo.jpg'}`}
            alt="avatar"
            className="avatar"
          />
          <div className="dropdown">
            <PopoverWrapper
              trigger={
                <div className="dropdown-toggle" id="dropdownMenuButton">
                  <DownIcon />
                </div>
              }
              content={<DropdownContent onLogout={onLogout} />}
              contentProps={{
                side: "bottom",
                align: "end",
                sideOffset: 10,
                alignOffset: -20,
                onOpenAutoFocus: (e) => {
                  e.preventDefault();
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
