import { useSetting } from "../../hooks/useStore";
import FullScreenLoader from "../Loader/FullScreenLoader";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AppContainer = ({ children }) => {
  const {
    setting: { mainLoader },
  } = useSetting();

  return (
    <>
      {mainLoader && <FullScreenLoader />}
      <div className="AppContainer">
        <Sidebar />
        <Header />
        <div id="content">{children}</div>
      </div>
    </>
  );
};

export default AppContainer;
