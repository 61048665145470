import { ReactComponent as RightArrowIcon } from "../assets/icons/right_arrow.svg";
import { getFormattedValue } from "../shared/utils";
import PropTypes from "prop-types";

const Ticker = ({ type = "watchlist", classes, item }) => {
  const renderHoldingTicker = () => {
    const {
      companyName,
      marketPrice,
      averageCost,
      symbol,
      position,
      marketValue,
    } = item;
    const change = marketPrice - averageCost;
    const changePercentage = (change / averageCost) * 100;
    const totalChange = change * position;

    return (
      <div className={`Ticker Ticker-holding ${classes || ""}`}>
        <div className="Ticker_left">
          <div className="Ticker_left_img">
            <img
              src={`https://fmpcloud.io/image-stock/${symbol}.png`}
              alt={symbol}
            />
          </div>
          <div className="Ticker_left_shares">
            <div className="Ticker_left_shares_quantity">
              <div className="label">{symbol}</div>
              <div className="quantity">x {position}</div>
            </div>
            <div className="Ticker_left_shares_name">{companyName}</div>
          </div>
        </div>

        <div className="Ticker_center">
          <div className="Ticker_center_price">
            <span className="old">{getFormattedValue(averageCost)}</span>
            <RightArrowIcon />
            <span className="new">{getFormattedValue(marketPrice)}</span>
          </div>
        </div>
        <div className="Ticker_right">
          <div className="Ticker_right_value">
            {getFormattedValue(marketValue)}
          </div>
          <div
            className={`Ticker_right_change ${
              totalChange > 0 ? "green" : "red"
            }`}
          >
            {getFormattedValue(totalChange)} ({totalChange > 0 && "+"}
            {getFormattedValue(changePercentage)}%)
          </div>
        </div>
      </div>
    );
  };

  const renderWatchlistTicker = () => (
    <div className={`Ticker Ticker-watchlist ${classes || ""}`}>
      <div className="Ticker_label">{item.symbol}</div>
      {item.name && (
        <>
          <div className="Ticker_name">{item.name}</div>
          <div className={`Ticker_change ${item.change > 0 ? "up" : "down"}`}>
            {getFormattedValue(item.change)} (
            {getFormattedValue(item.changesPercentage)}%)
          </div>
        </>
      )}
    </div>
  );

  return type === "watchlist" ? renderWatchlistTicker() : renderHoldingTicker();
};
export default Ticker;

Ticker.propTypes = {
  type: PropTypes.oneOf(["watchlist", "holding"]),
  classes: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.shape({
      companyName: PropTypes.string,
      marketPrice: PropTypes.number,
      averageCost: PropTypes.number,
      symbol: PropTypes.string,
      position: PropTypes.number,
      marketValue: PropTypes.number,
    }),
    PropTypes.shape({
      symbol: PropTypes.string,
      name: PropTypes.string,
      change: PropTypes.number,
      changesPercentage: PropTypes.number,
    }),
  ]),
};
