import React from "react";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as MarketIcon } from "../../assets/icons/market.svg";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import { ReactComponent as CommunityIcon } from "../../assets/icons/community.svg";
import logoRoundImg from "../../assets/images/logo_round.png";
import { NavLink } from "react-router-dom";
import SidebarItem from "./SidebarItem";

const upperSidebarItems = [
  {
    path: "/home",
    leftIcon: <DashboardIcon />,
    text: "Dashboard",
  },
  {
    path: "/market",
    leftIcon: <MarketIcon />,
    text: "Market",
  },
  {
    path: "/account",
    leftIcon: <AccountIcon />,
    text: "Account",
  },
  {
    path: "/community",
    leftIcon: <CommunityIcon />,
    text: "Community",
  },
];

const MiniSidebar = () => {
  return (
    <>
      <div className="MiniSidebar" id="miniSidebar">
        <div className="MiniSidebar_header">
          <NavLink to="/" className="logo">
            <img src={logoRoundImg} alt="Pasiv Financial" />
          </NavLink>
        </div>

        <div className="SidebarList">
          {upperSidebarItems.map((item, index) => (
            <SidebarItem
              path={item.path}
              leftIcon={item.leftIcon}
              rightIcon={item.rightIcon}
              text={item.text}
              key={index}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default MiniSidebar;
