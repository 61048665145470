import { useEffect, useRef, useState } from "react";
import { useMarket } from "../hooks/useStore";
import RadioChipInput from "./Input/RadioChipInput";
import { ReactComponent as NextButtonIcon } from "../assets/icons/down_btn.svg";
import { useHorizontalScroll } from "../hooks/useHorizontalScroll";
import MarketCard from "./MarketCard";

const filters = {
  Funds: [
    {
      label: "By Issuer",
      value: "issuer",
    },
    {
      label: "By Expense",
      value: "expenseRatio",
    },
    {
      label: "By Size",
      value: "nav",
    },
    {
      label: "By Theme",
      value: "themes",
    },
    {
      label: "By Sustainability",
      value: "sustain",
    },
  ],
  Stock: [
    {
      label: "Trending",
      value: "trending",
    },
    {
      label: "By Sector",
      value: "sector",
    },
  ],
};

const BrowseSection = () => {
  const {
    market: { browseFilterValues, browseData },
    updateBrowseFilter,
    getBrowseData,
  } = useMarket();
  const { currentValue, filterType } = browseFilterValues;

  const tagsRef = useHorizontalScroll();
  const bodyScrollRef = useRef(null);
  const [page, setPage] = useState(1);

  const handleTypeChange = (type) => {
    if (filterType === type) {
      return;
    }

    resetBodyScroll();
    updateBrowseFilter({ type, value: "" });
  };

  const handleValueChange = (value) => {
    if (currentValue === value) {
      return;
    }

    resetBodyScroll();
    updateBrowseFilter({ type: "", value });
  };

  const resetBodyScroll = () => {
    bodyScrollRef.current.scrollTo({
      top: 0,
      // behavior: "smooth",
    });

    setPage(1);
  };

  // Scroll the tags/filterValues to the right
  const handleNext = () => {
    tagsRef.current.scrollTo({
      left: tagsRef.current.scrollLeft + 300,
      behavior: "smooth",
    });
  };

  const handleBodyScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = bodyScrollRef.current;
    if (scrollTop >= scrollHeight - clientHeight) {
      setPage((prev) => prev + 1);
    }
  };

  // handle page change
  useEffect(() => {
    const newOffset = (page - 1) * 9;

    if (page === 1 || browseData.data?.length < newOffset) {
      return;
    }

    const skip = ["inTheNews", "topGainers", "topLosers"];
    if (skip.includes(currentValue)) {
      return;
    }

    getBrowseData({
      filterType: "",
      filterValue: "",
      offset: newOffset,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentValue]);

  useEffect(() => {
    tagsRef.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
  }, [tagsRef, browseFilterValues.data]);

  return (
    <div className="BrowseSection">
      <div className="BrowseSection_header">
        <div className="filters">
          {filters[browseFilterValues.marketType].map(({ label, value }) => (
            <RadioChipInput
              key={value}
              name="filter"
              value={value}
              label={label}
              selectedValue={browseFilterValues.filterType}
              onChange={() => handleTypeChange(value)}
            />
          ))}
        </div>

        <div className="tags">
          <div className="slider" ref={tagsRef}>
            {browseFilterValues.data?.length < 40 ? (
              <div className="slider_one">
                {browseFilterValues.data?.map(({ value, name }, index) => {
                  return (
                    <RadioChipInput
                      variant="type2"
                      key={`${index}_${value}`}
                      name="tag"
                      value={value}
                      label={name}
                      selectedValue={currentValue}
                      onChange={() => handleValueChange(value)}
                    />
                  );
                })}
              </div>
            ) : (
              <>
                <div className="slider_one">
                  {browseFilterValues.data
                    ?.filter((_, i) => i % 2 === 0)
                    .map(({ value, name }, index) => {
                      return (
                        <RadioChipInput
                          variant="type2"
                          key={`${index}_${value}`}
                          name="tag"
                          value={value}
                          label={name}
                          selectedValue={currentValue}
                          onChange={() => handleValueChange(value)}
                        />
                      );
                    })}
                </div>

                <div className="slider_two">
                  {browseFilterValues.data
                    ?.filter((_, i) => i % 2 !== 0)
                    .map(({ value, name }, index) => (
                      <RadioChipInput
                        variant="type2"
                        key={`${index}_${value}`}
                        name="tag"
                        value={value}
                        label={name}
                        selectedValue={currentValue}
                        onChange={() => handleValueChange(value)}
                      />
                    ))}
                </div>
              </>
            )}
          </div>

          {/* todo: change svg */}
          {browseFilterValues.data?.length > 0 && (
            <NextButtonIcon
              className="next-btn next-btn--browse-right"
              onClick={handleNext}
            />
          )}
        </div>
      </div>
      <div
        className="BrowseSection_body"
        ref={bodyScrollRef}
        onScroll={handleBodyScroll}
      >
        {browseData?.data?.map((stock, index) => (
          <MarketCard key={index} {...stock} />
        ))}
      </div>
    </div>
  );
};

export default BrowseSection;
