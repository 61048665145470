import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const PerformanceChart = ({ chart, ...props }) => {
  return (
    <Line
      {...props}
      data={{
        labels: chart.labels,
        datasets: [
          {
            fill: true,
            label: "Portfolio",
            data: chart.data,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            fill: true,
            label: "SPY",
            data: chart.spy,
            borderColor: "#94d3eb",
            backgroundColor: "rgba(148,211,235, 0.5)",
          },
        ],
      }}
      options={{
        // responsive: true,
        aspectRatio: 1,
        interaction: {
          mode: "index",
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 100,
          },
          line: {
            cubicInterpolationMode: "monotone",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            // stackWeight: 1.1,
            ticks: {
              callback: function (value, index, values) {
                return value.toFixed(2) + "%";
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      }}
    />
  );
};

export default PerformanceChart;
