import React from "react";
import { Redirect } from "react-router-dom";
import ComingSoon from "../components/ComingSoon";

import { Error, Landing, Home, Market } from "../pages/index";
import Account from "../pages/Account";
import PassiveIndex from "../pages/PassiveIndex";

// type: auth|public|private|error
const routes = [
  // ### public routes ###
  {
    path: "/",
    exact: true,
    component: Landing,
    type: "public",
  },

  {
    path: "/pasiv",
    exact: true,
    component: PassiveIndex,
    type: "public",
  },

  // ### private routes ###
  {
    path: "/coming-soon",
    exact: true,
    component: ComingSoon,
    type: "private",
  },

  {
    path: "/home",
    exact: true,
    component: Home,
    type: "private",
  },

  {
    path: "/market",
    exact: true,
    component: Market,
    type: "private",
  },

  {
    path: "/account",
    exact: true,
    component: Account,
    type: "private",
  },

  {
    path: "/community",
    exact: true,
    component: ComingSoon,
    type: "private",
  },

  // ### error ###
  { path: "/error", exact: true, component: Error, type: "error" },

  // ### * ###
  {
    path: "/",
    component: () => <Redirect to="/error" />,
    type: "error",
  },
];

export default routes;
