import { useEffect } from "react";
import ContentBox from "../components/ContentBox";
import Headline from "../components/Headline";
import MajorMarketSection from "../components/MajorMarketSection";
import { useMarket } from "../hooks/useStore";
import TinySlider from "tiny-slider-react";
import { useRef } from "react";
import { ReactComponent as DownButtonIcon } from "../assets/icons/down_btn.svg";
import MarketTable from "../components/MarketTable";
import BrowseSection from "../components/BrowseSection";
import CONST from "../shared/CONST";

const sliderSettings = {
  lazyload: true,
  items: 6,
  axis: "vertical",
  swipeAngle: false,
  speed: 400,
  mouseDrag: true,
  loop: true,
  controls: false,
};

const marketTypes = [
  { label: "Stocks", value: CONST.MARKET_TYPES.STOCK },
  { label: "Funds", value: CONST.MARKET_TYPES.ETF },
];
const Market = () => {
  const {
    market,
    getMajorMarket,
    getNews,
    getTopPerformers,
    getTrendingStocks,
    getBrowseFilterValues,
    updateMarketType,
  } = useMarket();
  const headlinesRef = useRef();

  const { marketType } = market.browseFilterValues;

  const handleHeadlineNext = () => {
    headlinesRef.current.slider.goTo("next");
  };

  useEffect(() => {
    console.log(market,"market")
    getMajorMarket();
    getNews();
    getTopPerformers();
    getTrendingStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBrowseFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketType]);

  return (
    <div className="Market">
      <ContentBox
        classes="Market_majorMarketBox"
        heading={<h1 className="box-heading">Major Markets</h1>}
        borderRadius="5px"
      >
        <MajorMarketSection />
      </ContentBox>

      <ContentBox
        classes="Market_headlinesBox"
        heading={<h1 className="box-heading">Headlines</h1>}
        borderRadius="5px"
      >
        {market.news?.data?.length > 0 && (
          <>
            <TinySlider settings={sliderSettings} ref={headlinesRef}>
              {market.news.data.map((headline) => (
                <Headline
                  key={headline._id}
                  title={headline.title}
                  url={headline.url}
                  date={headline.time}
                  img={headline.imageURL}
                  source={headline.source}
                />
              ))}
            </TinySlider>

            <DownButtonIcon className="next-btn" onClick={handleHeadlineNext} />
          </>
        )}
      </ContentBox>

      <ContentBox
        classes="Market_topGainersBox"
        heading={<h1 className="box-heading">Top Performing Funds</h1>}
        borderRadius="5px"
      >
        <MarketTable data={market.topPerformers.data} />
      </ContentBox>

      <ContentBox
        classes="Market_trendingBox"
        heading={<h1 className="box-heading">Trending Stocks</h1>}
        borderRadius="5px"
      >
        <MarketTable data={market.trendingStocks.data} />
      </ContentBox>

      <ContentBox
        classes="Market_browseBox"
        heading={
          <div className="Market_browseBox_header">
            <h1 className="box-heading">Browse</h1>
            <div className="options">
              {marketTypes.map(({ value, label }) => (
                <span
                  key={value}
                  onClick={() => updateMarketType(value)}
                  className={`option ${marketType === value ? "active" : ""}`}
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
        }
        borderRadius="10px"
      >
        <BrowseSection />
      </ContentBox>
    </div>
  );
};

export default Market;
