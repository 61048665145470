import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import mainService from "../../services/mainService";
import CONST from "../../shared/CONST";
import { createAlert } from "../../shared/utils";

const defaultFilterTypes = {
  [CONST.MARKET_TYPES.ETF]: "issuer",
  [CONST.MARKET_TYPES.STOCK]: "trending",
};

const initialState = {
  majorMarket: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
  news: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
  topPerformers: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
  trendingStocks: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
  browseFilterValues: {
    isLoading: false,
    isLoaded: false,
    data: null,
    marketType: CONST.MARKET_TYPES.ETF,
    filterType: defaultFilterTypes[CONST.MARKET_TYPES.ETF],
    currentValue: "",
  },
  browseData: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },

};

const getMajorMarket = createAsyncThunk("market/getMajorMarket", async () => {
  const response = await mainService.getMajorMarket();
  if (response.data.message === "success") {
    return response.data.data;
  }

  throw new Error(response.data.message);
});

const getNews = createAsyncThunk("market/getNews", async () => {
  const response = await mainService.getNews();
  if (response.data.message === "success") {
    return response.data.data;
  }

  throw new Error(response.data.message);
});





const getTopPerformers = createAsyncThunk(
  "market/getTopPerformers",
  async () => {
    const response = await mainService.getTopPerformers();

    if (response.status === 200) {
      return response.data.data;
    }

    throw new Error(response.message);
  }
);

const getTrendingStocks = createAsyncThunk(
  "market/getTrendingStocks",
  async () => {
    const trendingStocksRes = await mainService.getTrendingStocks();
    if (trendingStocksRes.data.message !== "success") {
      throw new Error(trendingStocksRes.data.message);
    }

    const tickers = trendingStocksRes.data.data.map((stock) => stock.symbol);
    const response = await mainService.getTickersPrice(tickers);

    if (response.status === 200) {
      return response.data.data;
    }

    throw new Error(response.message);
  }
);

const updateBrowseFilter = createAsyncThunk(
  "market/updateBrowseFilter",
  async ({ type, value }, { getState, dispatch }) => {
    const filterType = getState().market.browseFilterValues.filterType;
    const filterParams = {};

    if (type) {
      filterParams.filterType = type;
      dispatch(getBrowseFilterValues(type));
    } else if (value) {
      filterParams.currentValue = value;
      dispatch(
        getBrowseData({
          filterType: filterType,
          filterValue: value,
        })
      );
    }

    dispatch(marketActions.updateBrowseFilterParams(filterParams));
  }
);

const getBrowseFilterValues = createAsyncThunk(
  "market/getBrowseFilterValues",
  async (type, { getState, dispatch }) => {
    const { browseFilterValues } = getState().market;
    const { marketType, filterType } = browseFilterValues;
    const finalFilterType = type || filterType;

    const finalReturn = (data) => {
      const initialValue = data[0]?.value || "";
      dispatch(
        marketActions.updateBrowseFilterParams({ currentValue: initialValue })
      );
      dispatch(
        getBrowseData({
          filterType: finalFilterType,
          filterValue: initialValue,
        })
      );

      return data;
    };

    switch (finalFilterType) {
      case "issuer":
        const issuerRes = await mainService.getMarketTags(marketType);
        const issuerData = issuerRes.data.data.map(({ name }) => ({
          name: name,
          value: name,
        }));

        if (issuerRes.data.message === "success") {
          return finalReturn(issuerData);
        }

        throw new Error(issuerRes.data.message);

      case "expenseRatio":
        return finalReturn([
          {
            name: "All",
            value: "all",
          },
          {
            name: "0-1%",
            value: "0-1",
          },
          {
            name: "1-2%",
            value: "1-2",
          },
          {
            name: "2-5%",
            value: "2-5",
          },
          {
            name: "5%+",
            value: "5+",
          },
        ]);

      case "nav":
        return finalReturn([
          {
            name: "All",
            value: "all",
          },
          {
            name: "Small",
            value: "small",
          },
          {
            name: "Medium",
            value: "medium",
          },
          {
            name: "Large",
            value: "large",
          },
          {
            name: "Mega",
            value: "mega",
          },
        ]);

      case "themes":
        const themesRes = await mainService.getThemes();
        const themesData = themesRes.data.data.map((theme) => ({
          name: theme,
          value: theme,
        }));

        if (themesRes.data.message === "success") {
          return finalReturn(themesData);
        }

        throw new Error(themesRes.data.message);

      case "sustain":
        return finalReturn([
          {
            name: "High ESG Score (8+)",
            value: "HighESG",
          },
          { name: "Medium ESG Score (5+)", value: "MediumESG" },
          { name: "Low ESG Score (3+)", value: "LowESG" },
          { name: "High Carbon", value: "HighCarbon" },
          { name: "Low Carbon", value: "LowCarbon" },
        ]);

      case "trending":
        return finalReturn([
          {
            name: "In the News",
            value: "inTheNews",
          },
          { name: "Top Gainers", value: "topGainers" },
          { name: "Top Losers", value: "topLosers" },
        ]);

      case "sector":
        const sectorRes = await mainService.getMarketTags(marketType);
        const sectorData = sectorRes.data.data.map(({ name }) => ({
          name: name,
          value: name,
        }));

        if (sectorRes.data.message === "success") {
          return finalReturn(sectorData);
        }

        throw new Error(sectorRes.data.message);

      default:
        return [];
    }
  }
);

const getBrowseData = createAsyncThunk(
  "market/getBrowseData",
  async ({ filterType, filterValue, offset = 0, limit = 9 }, { getState }) => {
    const market = getState().market;
    const oldData = market.browseData.data;
    const finalType = filterType || market.browseFilterValues.filterType;
    const finalValue = filterValue || market.browseFilterValues.currentValue;

    let response = {
      data: {
        message: "Something went wrong",
      },
    };

    if (market.browseFilterValues.marketType === CONST.MARKET_TYPES.STOCK) {
      if (finalType === "sector") {
        response = await mainService.getTagData(
          finalValue,
          "Stocks",
          offset,
          limit
        );
      } else if (finalType === "trending" && finalValue === "topGainers") {
        const tickers = market.topPerformers.data.map((stock) => stock.ticker);
        response = await mainService.getTickersPrice(tickers);
        response.data.message = "success";
      } else if (finalType === "trending" && finalValue === "inTheNews") {
        response = await mainService.getTrendingStocks();
      }
      // TODO: top losers
    } else {
      response = await mainService.getBrowseData(
        finalType,
        finalValue,
        offset,
        limit
      );
    }

    if (response.data.message === "success") {
      if (offset && offset !== 0 && oldData?.length > 0) {
        return [...oldData, ...response.data.data];
      }
      return response.data.data;
    }

    throw new Error(response.data.message);
  }
);

// customReducers
const fulfilledReducer =
  (key) =>
    (state, { payload }) => {
      state[key].isLoading = false;
      state[key].isLoaded = true;
      state[key].data = payload;
    };

const rejectionReducer =
  (key) =>
    (state, { error }) => {
      state[key].isLoading = false;
      state[key].isLoaded = true;
      createAlert({
        type: "error",
        message: error.message || "Something went wrong",
      });
    };

const pendingReducer = (key) => (state) => {
  if (state[key]) {
    state[key].isLoading = true;
  }
};

const MarketReducer = createSlice({
  name: "market",
  initialState,
  reducers: {
    updateMarketType: (state, { payload }) => {
      state.browseFilterValues.marketType = payload;
      state.browseFilterValues.filterType = defaultFilterTypes[payload];
    },
    updateBrowseFilterParams: (state, { payload }) => {
      state.browseFilterValues = { ...state.browseFilterValues, ...payload };
    },
  },
  extraReducers: {
    [getMajorMarket.fulfilled]: fulfilledReducer("majorMarket"),
    [getMajorMarket.pending]: pendingReducer("majorMarket"),
    [getMajorMarket.rejected]: rejectionReducer("majorMarket"),

    [getNews.fulfilled]: fulfilledReducer("news"),
    [getNews.pending]: pendingReducer("news"),
    [getNews.rejected]: rejectionReducer("news"),



    [getTopPerformers.fulfilled]: fulfilledReducer("topPerformers"),
    [getTopPerformers.pending]: pendingReducer("topPerformers"),
    [getTopPerformers.rejected]: rejectionReducer("topPerformers"),

    [getTrendingStocks.fulfilled]: fulfilledReducer("trendingStocks"),
    [getTrendingStocks.pending]: pendingReducer("trendingStocks"),
    [getTrendingStocks.rejected]: rejectionReducer("trendingStocks"),

    [getBrowseFilterValues.fulfilled]: fulfilledReducer("browseFilterValues"),
    [getBrowseFilterValues.pending]: pendingReducer("browseFilterValues"),
    [getBrowseFilterValues.rejected]: rejectionReducer("browseFilterValues"),

    [getBrowseData.fulfilled]: fulfilledReducer("browseData"),
    [getBrowseData.pending]: pendingReducer("browseData"),
    [getBrowseData.rejected]: rejectionReducer("browseData"),
  },
});

export const marketActions = {
  ...MarketReducer.actions,
  getMajorMarket,
  getNews,
  getTopPerformers,
  getTrendingStocks,
  getBrowseFilterValues,
  getBrowseData,
  updateBrowseFilter,
};

export default MarketReducer.reducer;
