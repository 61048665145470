import generalSlice from "./generalSlice";
import marketSlice from "./marketSlice";
import settingSlice from "./settingSlice";

const allSlices = {
  setting: settingSlice,
  general: generalSlice,
  market: marketSlice,
};

export default allSlices;
