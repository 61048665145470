import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BalanceChart = ({ chart, ...props }) => {
  return (
    <Bar
      {...props}
      data={{
        labels: chart.labels,
        datasets: [
          {
            barThickness: 10,
            // categoryPercentage: 0.15,
            // barPercentage: 0.5,
            borderRadius: 4,
            label: "Stocks",
            data: chart.stocks,
            backgroundColor: "rgb(24, 116, 205)",
            stack: "stack1",
          },

          {
            barThickness: 10,
            borderRadius: 4,
            label: "Cash",
            data: chart.cash,
            backgroundColor: "rgb(141, 192, 242)",
            stack: "stack1",
          },
        ],
      }}
      options={{
        interaction: {
          mode: "index",
          intersect: false,
        },
        aspectRatio: 1,
        scales: {
          x: {
            // stackWeight: 0.9,
            grid: {
              display: false,
            },
          },
          y: {
            // more options here: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html
            beginAtZero: true,
            stackWeight: 2,
            grace: 1,
            alignToPixels: true,
            // bounds: "data",
            stacked: true,
            stack: "stack1",
            grid: {
              borderColor: "#fff",
            },
            ticks: {
              callback: function (value) {
                return "$" + value;
              },
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            labels: {
              boxHeight: 10,
              font: {
                size: 14,
              },
            },
          },
        },
      }}
    />
  );
};

export default BalanceChart;
