const RadioChipInput = ({
  name,
  label,
  value,
  selectedValue,
  variant = "type1",
  parentProps,
  ...inputProps
}) => {
  return (
    <div
      className={`RadioChipInput ${
        selectedValue === value ? "active" : ""
      } ${variant}`}
      {...parentProps}
    >
      <input
        id={`RadioChipInput_${name}_${value}`}
        type="radio"
        name={name}
        value={value}
        {...inputProps}
      />
      <label htmlFor={`RadioChipInput_${name}_${value}`}>{label}</label>
    </div>
  );
};

export default RadioChipInput;
