import { bindActionCreators } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { generalActions } from "../redux/slices/generalSlice";
import { marketActions } from "../redux/slices/marketSlice";
import { settingsActions } from "../redux/slices/settingSlice";

export const useSetting = () => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting, shallowEqual);

  return { setting, ...bindActionCreators(settingsActions, dispatch) };
};

export const useGeneral = () => {
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general, shallowEqual);

  return { general, ...bindActionCreators(generalActions, dispatch) };
};

export const useMarket = () => {
  const dispatch = useDispatch();
  const market = useSelector((state) => state.market, shallowEqual);

  return { market, ...bindActionCreators(marketActions, dispatch) };
};
