import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "tiny-slider/dist/tiny-slider.css";

import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import routes from "./routes";
import SiteRoute from "./routes/SiteRoute";

const App = () => {
  return (
    <>
      <div className="app-alert">
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnFocusLoss={false}
          // pauseOnHover
        /> */}
      </div>
      <div className="App">
        <Router>
          <Switch>
            {routes.map(({ path, type, component, exact }, id) => (
              <SiteRoute
                path={path}
                type={type}
                component={component}
                exact={exact}
                key={id}
              />
            ))}
          </Switch>
        </Router>
      </div>

      {/* Modals */}
    </>
  );
};

export default App;
