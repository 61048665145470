import * as Popover from "@radix-ui/react-popover";

const PopoverWrapper = ({ trigger, content, contentProps = {} }) => (
  <Popover.Root>
    <Popover.Trigger
      onFocus={(e) => {
        console.log({ e });
      }}
      asChild
    >
      {trigger}
    </Popover.Trigger>
    <Popover.Content {...contentProps}>
      {content}
      {/* <Popover.Close aria-label="Close">X</Popover.Close> */}
    </Popover.Content>
  </Popover.Root>
);

export default PopoverWrapper;
