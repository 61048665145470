import { useEffect, useState } from "react";
import Ticker from "./Ticker";
import { ReactComponent as DollarIcon } from "../assets/icons/dollar.svg";
import { getFormattedValue } from "../shared/utils";

const HoldingsTable = ({ data, cash, ...props }) => {
  const [viewAll, setViewAll] = useState({
    show: false,
    isOpen: false,
  });

  const toggleShowAll = () =>
    setViewAll((old) => ({
      ...old,
      isOpen: !old.isOpen,
    }));

  useEffect(() => {
    if (data.length > 3) {
      setViewAll((old) => ({
        ...old,
        show: true,
      }));
    }
  }, [data.length]);

  return (
    <div {...props}>
      <div className="HoldingsTable_row cash">
        <div className="left">
          <DollarIcon />
          <div className="label">Cash</div>
        </div>
        <div className="right">{getFormattedValue(cash?.amount)}</div>
      </div>
      {data.map((item, index) => {
        return (
          (index < 3 || viewAll.isOpen) && (
            <div key={item.symbol} className="HoldingsTable_row">
              <Ticker
                type="holding"
                item={item}
                classes="HoldingsTable_row_ticker"
              />
            </div>
          )
        );
      })}

      {viewAll.show && (
        <button className="HoldingsTable_viewAll" onClick={toggleShowAll}>
          {viewAll.isOpen ? "View Less" : "View All"}
        </button>
      )}
    </div>
  );
};

export default HoldingsTable;
