import { getFormattedNumber, getFormattedValue } from "../shared/utils";
import Ticker from "./Ticker";

const MarketCard = ({
  type = "Stocks",
  name,
  symbol,
  expenseRatio,
  carbonFootprint,
  esg,
  dividendYeild,
  pe,
  nav,
  return1Y,
  return5Y,
  marketCap,
}) => {
  const MarketCardItem = ({ label, value, prefix = "", suffix = "" }) => (
    <div className="MarketCard_body_item">
      <p className="label">{label}</p>
      <p className="value">
        {!value || value === "-1" ? "-" : `${prefix}${value}${suffix}`}
      </p>
    </div>
  );

  const renderBodyItems = () => {
    switch (type) {
      case "ETF":
        return (
          <>
            <MarketCardItem
              label="Expense Ratio"
              value={getFormattedValue(expenseRatio)}
              suffix="%"
            />

            <MarketCardItem
              label="Div Yield"
              value={getFormattedValue(dividendYeild, 1)}
              suffix="%"
            />

            <MarketCardItem
              label="NAV"
              value={getFormattedNumber(nav)}
              prefix="$"
            />

            <MarketCardItem
              label="1Yr Return"
              value={getFormattedValue(return1Y)}
              suffix="%"
            />

            <MarketCardItem
              label="Carbon Intensity"
              value={getFormattedValue(carbonFootprint)}
            />

            <MarketCardItem label="ESG Score" value={getFormattedValue(esg)} />
          </>
        );
      case "Stocks":
        return (
          <>
            <MarketCardItem label="PE Ratio" value={getFormattedValue(pe)} />

            <MarketCardItem
              label="Div Yield"
              value={getFormattedValue(dividendYeild, 1)}
              suffix="%"
            />

            {/* <MarketCardItem
              label="NAV"
              value={getFormattedValue(nav)}
              prefix="$"
            />

            <MarketCardItem
              label="1Yr Return"
              value={getFormattedValue(return1Y)}
              suffix="%"
            />

            <MarketCardItem
              label="5Yr Return"
              value={getFormattedValue(return5Y)}
              suffix="%"
            /> */}

            <MarketCardItem
              label="Market Cap."
              value={getFormattedNumber(marketCap)}
              prefix="$"
            />
          </>
        );

      default:
        return null;
    }
  };
  return (
    <div className="MarketCard">
      <div className="MarketCard_header">
        <div className="left">
          <img
            src={`https://fmpcloud.io/image-stock/${symbol}.png`}
            alt={symbol}
          />
          <span className="name">{name}</span>
        </div>
        <div className="right">
          <Ticker classes="MarketCard_header_ticker" item={{ symbol }} />
        </div>
      </div>

      <div className="MarketCard_body">{renderBodyItems()}</div>
      <div className="MarketCard_footer">
        <img
          className="MarketCard_footer_chart"
          src={`https://api.pasiv.io/api/v1/marketChart/${symbol}.png`}
          alt={symbol}
        />
      </div>
    </div>
  );
};

export default MarketCard;
