import { useEffect, useState } from "react";
import AllocationChart from "../components/Charts/Allocation.chart";
import BalanceChart from "../components/Charts/Balance.chart";
import PerformanceChart from "../components/Charts/Performance.chart";
import ContentBox from "../components/ContentBox";
import HoldingsTable from "../components/HoldingsTable";
import Ticker from "../components/Ticker";
import { useGeneral, useSetting } from "../hooks/useStore";
import mainService from "../services/mainService";
import { getFormattedValue } from "../shared/utils";
import http from "../services";
import Loader from "../components/Loader/FullScreenLoader"; // Import the Loader component

const range = [
  { label: "1D", value: "1D" },
  { label: "1W", value: "1W" },
  { label: "1M", value: "1M" },
  { label: "3M", value: "3M" },
  { label: "1YR", value: "1Y" },
  { label: "5YR", value: "5Y" },
];

const Home = () => {
  const {
    general,
    login,
    getPortfolio,
    getMonthlyBalance,
    getPerformance,
    getWatchlist,
  } = useGeneral();
  const { updateMainLoader, setting } = useSetting();
  const [userData, setUserData] = useState();
  const { portfolio, monthlyBalances, performance, watchlist } = general;

  const [allocationData, setAllocationData] = useState({
    chart: { labels: [], data: [] },
    holdingsData: [],
    totalValue: 0,
    abbreviation: "",
  });

  const [performanceData, setPerformanceData] = useState({
    chart: { labels: [], data: [], spy: [] },
    currentRange: "3M",
  });

  const [balanceData, setBalanceData] = useState({
    chart: { labels: [], stocks: [], cash: [] },
  });

  const [isLoading, setIsLoading] = useState(true); // Loader state

  useEffect(() => {
    const loadData = async () => {
      updateMainLoader(true);

      try {
        await login();
        await Promise.all([
          getPortfolio(),
          getMonthlyBalance(),
          getPerformance(performanceData.currentRange),
          getWatchlist(),
        ]);
      } finally {
        // Set loader to false after 3 seconds
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRangeChange = (value) => {
    setPerformanceData((oldData) => ({
      ...oldData,
      currentRange: value,
    }));

    getPerformance(value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("get latest portfolio");
      mainService.isMarketOpen().then((res) => {
        if (res.data.data?.isTheStockMarketOpen) {
          getPortfolio();
          getWatchlist();
        }
      });
    }, 5000);
    return () => clearInterval(interval); // Ensure this cleanup is a function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!portfolio.data) {
      return;
    }

    const cashValue = parseFloat(portfolio.data[0].amount, 10);
    let totalValue = cashValue;

    const newHoldingsData = [...portfolio.data[1].positions];
    newHoldingsData.sort((a, b) => b.marketValue - a.marketValue);

    const labels = newHoldingsData.map((item) => item.symbol);
    labels.unshift("Cash");

    const data = newHoldingsData.map((item) => {
      totalValue += item.marketValue;
      return item.marketValue;
    });
    data.unshift(cashValue);

    setAllocationData({
      chart: { labels, data },
      holdingsData: newHoldingsData,
      totalValue: totalValue.toFixed(2),
      abbreviation: portfolio.data[0].abbreviation,
    });
  }, [portfolio]);

  useEffect(() => {
    if (!performance.data) {
      return;
    }

    const data = performance.data.map((item) => item.portfolio);
    const spy = performance.data.map((item) => item.spy);
    const dates = performance.data.map((item) =>
      new Date(item.date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    );

    setPerformanceData((oldData) => ({
      ...oldData,
      chart: { labels: dates, data, spy },
    }));
  }, [performance.data]);

  useEffect(() => {
    if (!monthlyBalances.data) {
      return;
    }

    let stocks = [],
      cash = [],
      dates = [];

    monthlyBalances.data.forEach((element) => {
      stocks.push(element.stock);
      cash.push(element.cash);
      dates.push(element.month);
    });

    setBalanceData((oldData) => ({
      ...oldData,
      chart: { labels: dates, stocks, cash },
    }));
  }, [monthlyBalances]);

  useEffect(() => {
    if (
      portfolio.isLoaded &&
      !portfolio.isLoading &&
      monthlyBalances.isLoaded &&
      !monthlyBalances.isLoading &&
      performance.isLoaded &&
      !performance.isLoading &&
      setting.mainLoader
    ) {
      updateMainLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    portfolio.isLoading,
    monthlyBalances.isLoaded,
    performance.isLoaded,
    portfolio.isLoading,
    monthlyBalances.isLoading,
    performance.isLoading,
    setting.mainLoader,
  ]);

  const getGreetingMessage = () => {
    const timeNow = new Date().getHours();

    if (timeNow <= 12) {
      return "Good Morning,";
    } else if (timeNow > 12 && timeNow <= 18) {
      return "Good Afternoon,";
    } else if (timeNow > 18 && timeNow < 24) {
      return "Good Evening,";
    } else {
      return "Good Night,";
    }
  };
  const fetchUserData = async () => {
    const getUser = await http.post(`order/orderType`);
    console.log(getUser.data, "9999----");
    setUserData(getUser.data.userDetails);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (isLoading) {
    return <Loader />; // Render the loader
  }

  return (
    <div className="Home">
      <ContentBox borderRadius="3px" classes="Home_intro">
        <div className="Home_intro_text">
          <h2 className="greetings">
            {getGreetingMessage()} {userData ? userData.firstName :null}.
          </h2>
          <span className="market-stat">
            Your portfolio is up 6.25% today. The S&P500 benchmark is down
            -3.56%.
          </span>
          <span className="date">11 June, 2024</span>
        </div>
        {/* <div className="Home_intro_ctas">
          <button className="cta">Deposit</button>
          <button className="cta">Withdraw</button>
        </div> */}
      </ContentBox>

      <ContentBox
        heading={<h1 className="box-heading">Allocation</h1>}
        borderRadius="8px"
        classes="Home_allocation"
      >
        <AllocationChart
          className="Home_allocation_chart"
          chart={allocationData.chart}
        />
        <h2 className="Home_allocation_title">
          <span className="value">
            $ {getFormattedValue(allocationData.totalValue)}{" "}
          </span>
        </h2>
      </ContentBox>

      <ContentBox
        heading={<h1 className="box-heading">Performance</h1>}
        borderRadius="8px"
        classes="Home_performance"
      >
        <PerformanceChart
          className="Home_performance_chart"
          chart={performanceData.chart}
        />

        <div className="Home_performance_range">
          {range.map((item) => (
            <button
              key={item.value}
              className={`Home_performance_range_item ${
                item.value === performanceData.currentRange && "active"
              }`}
              onClick={() => handleRangeChange(item.value)}
            >
              {item.label}
            </button>
          ))}
        </div>
      </ContentBox>

      <ContentBox
        heading={<h1 className="box-heading">Balance</h1>}
        borderRadius="8px"
        classes="Home_balance"
      >
        <BalanceChart className="Home_balance_chart" chart={balanceData.chart} />
      </ContentBox>

      <ContentBox
        heading={<h1 className="box-heading">Holdings</h1>}
        borderRadius="8px"
        classes="Home_holdings span-2"
      >
        <HoldingsTable
          className="Home_holdings_table"
          data={allocationData.holdingsData}
          cash={portfolio.data?.length > 0 ? portfolio.data[0] : 0}
        />
      </ContentBox>

      <ContentBox
        heading={<h1 className="box-heading">Watchlist</h1>}
        borderRadius="8px"
        classes="Home_watchlist"
      >
        {watchlist.data?.map((item) => (
          <Ticker key={item.symbol} item={item} />
        ))}
      </ContentBox>
    </div>
  );
};

export default Home;
