import { toast } from "react-toastify";

export const createAlert = ({ type, message }, config = {}) =>
  toast[type](message, { ...config });

export const getLocalStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return "";
  }
};

export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

// for visual purpose only
export const getFormattedValue = (value, dp = 2) => {
  if (value) {
    value = typeof value === "string" ? Number(value) : value;
    return (
      value
        .toFixed(dp)
        // .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }
  return value;
};

export const getFormattedNumber = (value) => {
  if (value) {
    value = typeof value === "string" ? Number(value) : value;
    if (value >= 1000000000000) {
      return `${(value / 1000000000000).toFixed(2)}T`;
    }
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(1)}B`;
    }
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }

    return value;
  }
  return value;
};
