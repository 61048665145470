import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "../components/Input/PhoneInput";
import heroImg from "../assets/images/hero_1.png";
import logoRoundImg from "../assets/images/logo_round.png";
import mainLoderGif from "../assets/main-loader.gif";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import OtpInput from "react-otp-input";
import mainService from "../services/mainService";

import { useHistory } from "react-router-dom";
import firebase from "../shared/firebase";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { createAlert } from "../shared/utils";
import { useDispatch } from "react-redux";
import { generalActions } from "../redux/slices/generalSlice";

function Landing() {
  const [currentStep, setCurrentStep] = useState(0);
  const [otpStatus, setOtpStatus] = useState({
    verifying: false,
    verified: false,
  });

  const history = useHistory();
  const auth = getAuth(firebase);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      page_1: {
        country_code: "+971",
        phone_number: "",
      },
      page_2: {
        otp_value: "",
      },
    },
  });

  const countryCodeValue = watch("page_1.country_code");

  const configureCaptcha = async () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("captcha resolved", { response });
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const sendOtp = async (phoneWithCode, isResend = false) => {
    if (!isResend) {
      await configureCaptcha();
    }

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneWithCode, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent", confirmationResult);
        setCurrentStep(1); // go to next step
        if (isResend) {
          setValue("page_2.otp_value", "");
          setError("page_2.otp_value", null);
          disableSendOtp();
          createAlert({
            type: "info",
            message: "OTP has been resent",
          });
        }
      })
      .catch((error) => {
        console.log(error)
        createAlert({
          type: "error",
          message: error.message || "Something went wrong",
        });
        console.log("OTP not sent");
      });
  };

  const verifyOtp = async (otp) => {
    setOtpStatus({
      verifying: true,
      verified: false,
    });
    await window.confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        dispatch(generalActions.updateUser(user));
        createAlert({
          type: "success",
          message: "OTP verified successfully",
        });
        setOtpStatus({
          verifying: false,
          verified: true,
        });
      })
      .catch((error) => {
        // User couldn't sign in
        setError("page_2.otp_value", {
          type: "validation",
          message: "The code you entered was incorrect. Try sending again.",
        });
        setOtpStatus({
          verifying: false,
          verified: false,
        });
      });
  };

  // disable for 2 minutes
  const disableSendOtp = () => {
    const otpButton = document.getElementById("otp-button");
    otpButton.disabled = true;
    otpButton.innerHTML = "Resend OTP in 2 minutes";
    let count = 120;
    const interval = setInterval(() => {
      count--;
      otpButton.innerHTML = `Resend OTP in ${count} seconds`;
      if (count === 0) {
        clearInterval(interval);
        otpButton.disabled = false;
        otpButton.innerHTML = "Resend OTP";
      }
    }, 1000);
  };

  const resendOtp = async () => {
    const phoneWithCode = countryCodeValue + watch("page_1.phone_number");
    await sendOtp(phoneWithCode, true);
  };

  const onSubmit = async (data) => {
    const { country_code, phone_number } = data.page_1;
    const { otp_value } = data.page_2;

    if (currentStep === 0) {
      const res = await mainService.checkUserStatus(
        country_code.slice(1) + phone_number
      );

      if (res?.data?.data === "Found") {
        await sendOtp(country_code + phone_number);
      } else if (res?.data?.data === "NotFound") {
        history.push("/error?message=signup");
      }

      return;
    }

    // verify otp : 2nd step
    await verifyOtp(otp_value);
  };

  const Logo = () => (
    <img
      className="Landing_right-logo"
      src={isSubmitting ? mainLoderGif : logoRoundImg}
      alt="pasiv financial"
    />
  );

  const renderForm = () =>
    currentStep === 0 ? (
      <motion.div
        key="step-1"
        initial={{ opacity: 1, x: 1000 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: "spring", duration: 0.8 }}
        className="Landing_right_step-1"
      >
        <Logo />

        <form className="Landing_right-form" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="Landing_right-form_heading">
            Enter your phone number to login to Pasiv
          </h1>

          <Controller
            render={({ field, fieldState: { error } }) => (
              <PhoneInput
                {...field}
                placeholder="Phone Number"
                error={error?.message}
                leftDefault={countryCodeValue}
                onLeftChange={(value) => {
                  setValue("page_1.country_code", value);
                }}
                onChange={(e) => {
                  e.target.value = e.target.value
                    .split(/ /)[0]
                    .replace(/[^\d]/g, "");
                  field.onChange(e);
                }}
              />
            )}
            control={control}
            name="page_1.phone_number"
            rules={{
              required: "Phone number is required",
            }}
          />

          <button className={`btn ${isValid ? "active" : ""}`} type="submit">
            Login
          </button>

          <p className="form-footer">
            Not on Pasiv?{" "}
            <a className="form-footer_link" href="/error?message=download">
              Create an Account
            </a>
          </p>
        </form>

        <footer>
          Pasiv Financial Ltd (Pasiv) is a registered financial services
          provider in the Dubai International Financial Centre ( DIFC)
          jurisdiction of the United Arab Emirates with a restricted Category 4
          license #CL3527. Pasiv is regulated by the Dubai Financial Services
          Authority ( DFSA) and is currently operating under the DFSA's
          Innovation Testing Framework. Due to the restricted nature of its
          Licence, normal requirements and Client protections may not apply and
          Clients may have limited rights if they suffer loss as a result of
          taking part in testing of its Service. U.S. securities products and
          services are offered through ChoiceTrade. There is always the
          potential of losing money when you invest in securities or other
          financial products. Investors should consider their investment
          objectives and risks carefully before investing. The price of a given
          security may increase or decrease based on market conditions and
          customers may lose money, including their original investment.
          Testimonials may not be representative of the experience of other
          customers and are not guarantees of future performance or success.©
          2022. Pasiv Financial. All rights reserved.
        </footer>
      </motion.div>
    ) : (
      <motion.div
        key="step-2"
        initial={{ opacity: 0, y: -500 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", duration: 1 }}
        className="Landing_right_step-2"
      >
        <Logo />

        <form className="Landing_right-form" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="Landing_right-form_heading">
            Enter the One Time Password (OTP) sent to your phone number
          </h1>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <OtpInput
                  {...field}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  hasErrored={!!error}
                  className="otp-input"
                />

                <AnimatePresence>
                  {error?.type === "validation" ? (
                    <motion.p
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ type: "spring", duration: 0.8 }}
                      className="otp-input-error"
                    >
                      {error?.message}
                    </motion.p>
                  ) : (
                    <div className="otp-input-error hidden" />
                  )}
                </AnimatePresence>
              </>
            )}
            control={control}
            name="page_2.otp_value"
            rules={{ required: true }}
          />
          {!otpStatus.verified ? (
            <motion.button
              whileTap={{ scale: 0.9 }}
              disabled={!isValid || isSubmitting}
              className={`btn ${isValid ? "active" : ""} ${errors?.page_2?.opt_value?.type !== "validation"
                  ? "errorNotPresent"
                  : ""
                }`}
              type="submit"
            >
              Verify
              {otpStatus.verifying ? "ing ..." : ""}
            </motion.button>
          ) : (
            <CheckIcon className="Landing_right-form-verified-check" />
          )}
          <p className="form-footer">
            Didn’t receive a code?
            <button
              id="otp-button"
              type="button"
              onClick={resendOtp}
              className="form-footer_link form-footer_link-btn "
            >
              Resend
            </button>
          </p>
        </form>
      </motion.div>
    );

  return (
    <>
      <div className="Landing">
        <div
          className="Landing_left"
          style={{
            background: `url(${heroImg}) no-repeat center center/cover`,
          }}
        />
        <div className="Landing_right">{renderForm()}</div>
      </div>
    </>
  );
}

export default Landing;
