import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCQd_Q0VdLJi9Qj32CKzL3Jh9Coh7cztJ8",
//   authDomain: "whatappclone-595dc.firebaseapp.com",
//   databaseURL: "https://whatappclone-595dc.firebaseio.com",
//   projectId: "whatappclone-595dc",
//   storageBucket: "whatappclone-595dc.appspot.com",
//   messagingSenderId: "968296238055",
//   appId: "1:968296238055:web:9e1cc65b092cb760a84b42",
//   measurementId: "G-20CGG86D3H",
// };

const firebaseConfig = {
  apiKey: "AIzaSyAlzgEsBxmAagm_jd1oAFKb63HUnbYIx1Y",
  authDomain: "pasiv-lite.firebaseapp.com",
  projectId: "pasiv-lite",
  storageBucket: "pasiv-lite.appspot.com",
  messagingSenderId: "576497526446",
  appId: "1:576497526446:web:2633d7810072a86804ef3f",
  measurementId: "G-BVXX3H5V6R"
};

const firebase = initializeApp(firebaseConfig);

export const getDocFromDB = async (collection, documentId) => {
  const db = getFirestore();
  const docRef = doc(db, collection, documentId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  }

  console.log("No such document!");
  return null;
};

export default firebase;
