const Headline = ({ title, date, img, link, url, source, ...props }) => {
  const formattedDate = new Date(date * 1000).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className="Headline" {...props}>
      <div className="Headline_img">
        <img src={img} alt={title} />
      </div>
      <div className="Headline_body">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="title"
        >
          {title}
        </a>
        <div className="date">{formattedDate}</div>

        <div className="source">
          <a href={link}>{source}</a>
        </div>
      </div>
    </div>
  );
};

export default Headline;
