/* eslint-disable import/no-anonymous-default-export */
export default {
  SIDEBAR: {
    NO_SIDEBAR: 1500,
    WIDTH: 240,
    MINI_WIDTH: 74, //MAYBE
  },

  MARKET_TYPES: {
    STOCK: "Stock",
    ETF: "Funds",
  },

  BREAKPOINTS: {
    MOBILE: 1190,
  },

  JWT_TOKEN: "jwt_token",

  MESSAGES: {
    PAGE_ERROR: {
      SIGNUP: "Oops! You need to signup ﬁrst.",
      DOWNLOAD: "Oops! Please download the app.",
      DEFAULT: "Oops! Something went wrong. Please try again later.",
    },
    INVALID_CREDENTIALS: "Invalid Credentials",
  },

  LAUNCH_DATE: "04/04/2023",

  DOWNLOAD_LINKS: {
    IOS: "https://apps.apple.com/us/app/pasiv-investing-with-a-i/id1496494065",
    ANDROID: "https://play.google.com/store/apps/details?id=com.pasiv.pasiv",
  },
};
