import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

//  import useCloseOnOutside from "../../hooks/useCloseOnOutside";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as MarketIcon } from "../../assets/icons/market.svg";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import { ReactComponent as CommunityIcon } from "../../assets/icons/community.svg";
import logoImg from "../../assets/images/logopng2022.png";
import logoRoundImg from "../../assets/images/logo_round.png";

import SidebarItem from "./SidebarItem";
import CONST from "../../shared/CONST";
import MiniSidebar from "./MiniSidebar";
import useResize from "../../hooks/useResize";

const upperSidebarItems = [
  {
    path: "/home",
    leftIcon: <DashboardIcon />,
    text: "Dashboard",
  },
  {
    path: "/market",
    leftIcon: <MarketIcon />,
    text: "Market",
  },
  {
    path: "/account",
    leftIcon: <AccountIcon />,
    text: "Account",
  },
  {
    path: "/community",
    leftIcon: <CommunityIcon />,
    text: "Community",
  },
];

const Sidebar = () => {
  const { width } = useResize();
  const sidebarRef = useRef();

  return (
    <>
      <AnimatePresence>
        {width >= CONST.SIDEBAR.NO_SIDEBAR && (
          <motion.div
            animate={{ x: 0, opacity: 1 }}
            initial={{ x: "-20vw", opacity: 0 }}
            transition={{
              ease: "easeOut",
              duration: 0,
            }}
            exit={{ x: "-20vw", transition: { duration: 0 } }}
            className="Sidebar"
            id="sidebar"
            ref={sidebarRef}
          >
            <div className="Sidebar_header">
              <NavLink to="/" className="logo">
                <img src={logoImg} alt="Pasiv Financial" />
              </NavLink>
            </div>

            <div className="SidebarList">
              {upperSidebarItems.map((item, index) => (
                <SidebarItem
                  path={item.path}
                  leftIcon={item.leftIcon}
                  rightIcon={item.rightIcon}
                  text={item.text}
                  key={index}
                />
              ))}
            </div>

            <div className="Sidebar_footer">
              <img src={logoRoundImg} alt="Footer logo" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <MiniSidebar />
    </>
  );
};

export default Sidebar;

Sidebar.defaultProps = {
  toggle: true,
  onClose: () => {},
};

Sidebar.propTypes = {
  toggle: PropTypes.bool,
  onClose: PropTypes.func,
};
