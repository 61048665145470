import http from ".";

/**
 * 	Get
 */

/**
 * 	Post
 */
// temp
const tempUserData = {
  phone: "+447377082345",
  password: "Akshay1@",
  isDemo: true,
  role: "USER",
};
const login = (data = tempUserData) => http.post("/users/sendotp", data);

// https://api.pasiv.io/api/v1/users/sendotp
/**
 * 	Put
 */

/**
 * 	Delete
 */

const authService = { login };

export default authService;
