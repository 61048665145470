import http, { http_security } from ".";

/**
 * 	Get
 */

const checkUserStatus = (number) =>
  http_security.get(`customer/checkUser?user=${number}`);

const getMonthlyBalance = (userId) =>
  http_security.get(`timeSeries/getMonthlyPortfolioBalance?user=${userId}`);

const getMajorMarket = () => http_security.get("/securities/futuresData");

const getNews = (offset = 0, limit = 10) =>
  http_security.get(
    `/customer/getTrendingNews?offset=${offset}&limit=${limit}`
  );

const getTickersData = (tickers) =>
  http_security.get(`/securities/all?symbols=${tickers}`);

const getMarketTags = (type = "Stock") =>
  http_security.get(`/securities/marketTags?type=${type}`);

const getThemes = () => http_security.get(`/securities/themes`);

const getTagData = (tag, type = "Stocks", offset, limit) =>
  http_security.get(
    `/securities/?type=${type}&offset=${offset}&limit=${limit}&tag=${tag}`
  );

const getBrowseData = (filterType, filterValue, offset, limit) =>
  http_security.get(
    `/securities/v2/fundscreener?${filterType}=${filterValue}&offset=${offset}&limit=${limit}`
  );

const getTrendingStocks = () => http_security.get("securities/trending");

const getTickersPrice = (tickers) =>
  http_security.post(`securities/getBatchSymbol`, { symbols: tickers });

const getTopPerformers = () => http_security.get("securities/FMPGainers");

const isMarketOpen = () => http_security.get(`securities/isMarketOpen`);
const getScoops = () =>
  http_security.get(`securities/scoops`);
/**
 * 	Post
 */

const getPortfolio = () => http.post("/broker/portfolio");

const getPerformance = (data) =>
  http.post("/market/protfolio_market", data);

const mainService = {
  checkUserStatus,
  getMonthlyBalance,
  getMajorMarket,
  getNews,
  getTickersData,
  getMarketTags,
  getThemes,
  getTagData,
  getBrowseData,
  getTrendingStocks,
  getTickersPrice,
  getTopPerformers,
  isMarketOpen,
  getPortfolio,
  getPerformance,
  getScoops
};

export default mainService;
