import { useLocation } from "react-router-dom";
import CONST from "../shared/CONST";
import appStoreImg from "../assets/images/app_store.png";
import playStoreImg from "../assets/images/play_store.png";
import logoRoundImg from "../assets/images/logo_round.png";

const getErrorMessage = (reason) => {
  if (!reason) {
    reason = "default";
  }

  return CONST.MESSAGES.PAGE_ERROR[reason.toUpperCase()];
};

const Error = () => {
  const { search } = useLocation();
  const reason = search.split("=")[1];

  console.log({ search: search.split("=")[1] });
  return (
    <div className="Error">
      <div className="home-link">
        <a href="/">
          <img src={logoRoundImg} alt="logo" />
        </a>
      </div>

      <div className="Error_container">
        <h1 className="Error_container_heading">{getErrorMessage(reason)}</h1>
        <p className="Error_container_message">
          Account signup is only available on mobile at this time. Download the
          app from any of the app stores below to get started with a free
          account.
        </p>
        <div className="Error_container_buttons">
          <a
            href={CONST.DOWNLOAD_LINKS.IOS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appStoreImg} alt="app store link" />
          </a>
          <a
            href={CONST.DOWNLOAD_LINKS.ANDROID}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={playStoreImg} alt="play store link" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error;
