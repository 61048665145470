import mainLoderGif from "../../assets/main-loader.gif";

const FullScreenLoader = () => {
  return (
    <div className="FullScreenLoader">
      <div className="loader">
        <img className="logo" src={mainLoderGif} alt="pasiv financial" />
        <p>
          Web Dashboard
          <br />
          Loading ...
        </p>
      </div>
    </div>
  );
};

export default FullScreenLoader;
