import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const AllocationChart = ({ chart, ...props }) => {
  return (
    <Doughnut
      {...props}
      data={{
        labels: chart.labels,
        datasets: [
          {
            label: "Allocation",
            data: chart.data,
            backgroundColor: [
              "#D3D3D3",
              "#64C9ED",
              "#326195",
              "#0078b1",
              "#00a6d0",
              "#39b6da",
              "#94d3eb",
              "#0018F9",
            ],
            // borderColor: ["#fff"],
            borderWidth: 0,
          },
        ],
      }}
      options={{
        animation: {
          duration: 2500,
          // easing: "easeInOutCirc",
        },
        layout: {
          padding: 10,
        },
        interaction: {
          mode: "index",
          intersect: false,
        },
        rotation: -4 * Math.PI,
        plugins: {
          legend: false,
          tooltip: {
            enabled: false,
          },
        },
      }}
      plugins={[
        // {
        //   beforeDraw: (chart) => {
        //     const width = chart.width;
        //     const height = chart.height;
        //     const ctx = chart.ctx;

        //     ctx.restore();
        //     const fontSize = (height / 114).toFixed(2);
        //     ctx.font = `${fontSize}em sans-serif`;
        //     ctx.textBaseline = "middle";

        //     const text = chart.data.datasets[0].data[0];
        //     const textX = Math.round((width - ctx.measureText(text).width) / 2);
        //     const textY = height / 2;

        //     ctx.fillText(text, textX, textY);
        //     ctx.save();
        //   },
        // },

        {
          afterDraw: (chart) => {
            const {
              ctx,
              chartArea: { width, height },
            } = chart;

            chart.data.datasets.forEach((dataset, i) => {
              chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                const { x, y } = datapoint.tooltipPosition();

                // draw line
                const diff = 15;
                const halfWidth = width / 2;
                const halfHeight = height / 2;

                let xLine = x >= halfWidth ? x + diff : x - diff;
                let yLine = y >= halfHeight ? y + diff : y - diff;
                let extraLine = x >= halfWidth ? 15 : -15;

                // const borderX = datapoint.getProps
                // console.log({ datapoint, dataset });
                // console.log(datapoint.circumference, chart.data.labels[index]);

                if (datapoint.circumference < 0.2) {
                  xLine = x >= halfWidth ? xLine + diff : xLine - diff;
                  yLine = y >= halfHeight ? yLine + diff : yLine - diff;
                }

                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.lineTo(xLine, yLine);
                ctx.lineTo(xLine + extraLine, yLine);
                // ctx.strokeStyle = dataset.backgroundColor[index];
                ctx.strokeStyle = "gray";
                ctx.stroke();

                // draw text
                // const textWidth = ctx.measureText(
                //   chart.data.labels[index]
                // ).width;
                ctx.font = "1.5rem AvenirBook";
                const text = chart.data.labels[index];

                // control text position
                const offset = x >= halfWidth ? 5 : -5;
                const textX = xLine + extraLine + offset;
                const textY = yLine;
                ctx.fillStyle = "#000";
                const align = x >= halfWidth ? "left" : "right";
                ctx.textAlign = align;
                ctx.textBaseline = "middle";
                ctx.fillText(text, textX, textY);
              });
            });
          },
        },
      ]}
    />
  );
};

export default AllocationChart;
